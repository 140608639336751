/**
 撤銷重做功能
 */
export class Revoke {
  // 歷史記錄
  recordList = [];

  // 撤銷記錄，用於重做
  redoList = [];

  // 當前記錄用currentRecord變量暫時存儲，當用戶修改時，再存放到recordList
  currentRecord = null;

  // 上次插入數據時間
  time = 0;

  /**
   * @description: 插入歷史記錄
   * @param {object}record
   * @return {boolean}
   */
  push(record) {
    const nowTime = Date.now()
    // 防止添加重復的時間，當添加間隔小於100ms時，則替換當前記錄並取消執行添加
    if (this.time + 100 > nowTime) {
      this.currentRecord = JSON.stringify(record)
      return false
    }

    this.time = nowTime

    // 判斷之前是否已經存在currentRecord記錄，有則存儲到recordList
    if (this.currentRecord) {
      this.recordList.push(this.currentRecord)
      // 增加記錄後則應該清空重做記錄
      this.redoList.splice(0, this.redoList.length)
    }

    // 將json轉成字符串存儲
    this.currentRecord = JSON.stringify(record)

    // 最多存儲20條記錄，超過20天記錄則刪除之前的記錄
    if (this.length > 20) {
      this.recordList.unshift()
    }

    return true
  }

  /**
   * @description: 撤銷操作
   * @param {*}
   * @return {object}
   */
  undo() {
    // 沒有記錄時,返回false
    if (this.recordList.length === 0) {
      return false
    }
    const record = this.recordList.pop()

    // 將當前記錄添加到重做記錄裏面
    if (this.currentRecord) {
      this.redoList.push(this.currentRecord)
    }
    // 丟棄當前記錄，防止重復添加
    this.currentRecord = null

    return JSON.parse(record)
  }

  /**
   * @description: 重做操作
   * @param {*}
   * @return {*}
   */
  redo() {
    // 沒有重做記錄時,返回false
    if (this.redoList.length === 0) {
      return false
    }

    const record = this.redoList.pop()
    // 添加到重做記錄裏面
    if (this.currentRecord) {
      this.recordList.push(this.currentRecord)
    }
    // 丟棄當前記錄，防止重復添加
    this.currentRecord = null

    return JSON.parse(record)
  }
}
