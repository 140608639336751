//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import msgList from './dialogMsgList.vue'
export default {
    components: {
        msgList,
    },
    data() {
        return {
            dialogMsgVisable: false,
            isUnRead: false,
            isClearMsg: false,
        }
    },
    computed: {
        unReadNum() {
            return this.$store.state.msg.unReadNum
        },
    },
    methods: {
        handleChange(evt) {
            this.isUnRead = evt === 2
        },
        close() {
            this.$emit('close')
        },
        open() {
            this.isClearMsg = false
            this.dialogMsgVisable = true
        },
    },
}
