//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import draggable from 'vuedraggable'
export default {
  name: 'CollapseItem',
  props: ['list'],
  components: {
    draggable
  },
  methods: {
    handleStart(e, list) {
      this.$emit('start', list[e.oldIndex].type)
    }
  }
}
