//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ALL_APPS_MENU } from '@/store/mutation-types'
import { mixin } from '@/utils/mixin'
import { message } from 'ant-design-vue/es'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import SMenu from '../Menu/'
import Logo from '../tools/Logo'
import UserMenu from '../tools/UserMenu'

export default {
    name: 'GlobalHeader',
    components: {
        UserMenu,
        SMenu,
        Logo,
    },
    computed: {
        ...mapGetters(['userInfo']),
    },
    created() {
        this.defApp.push(Vue.ls.get(ALL_APPS_MENU)[0].code)
    },
    mixins: [mixin],
    props: {
        mode: {
            type: String,
            // sidemenu, topmenu
            default: 'sidemenu',
        },
        menus: {
            type: Array,
            required: true,
        },
        theme: {
            type: String,
            required: false,
            default: 'dark',
        },
        collapsed: {
            type: Boolean,
            required: false,
            default: false,
        },
        device: {
            type: String,
            required: false,
            default: 'desktop',
        },
    },
    data() {
        return {
            visible: true,
            oldScrollTop: 0,
            defApp: [],
        }
    },
    mounted() {
        document.addEventListener('scroll', this.handleScroll, { passive: true })
    },
    methods: {
        ...mapActions(['MenuChange']),

        /**
         * 應用切換
         */
        switchApp(appCode) {
            this.defApp = []
            const applicationData = this.userInfo.apps.filter((item) => item.code === appCode)
            const hideMessage = message.loading('正在切換應用！', 0)
            this.MenuChange(applicationData[0])
                .then((res) => {
                    hideMessage()
                    // eslint-disable-next-line handle-callback-err
                })
                .catch((err) => {
                    message.error('應用切換異常')
                })
        },
        handleScroll() {
            if (!this.autoHideHeader) {
                return
            }

            const scrollTop = document.body.scrollTop + document.documentElement.scrollTop
            if (!this.ticking) {
                this.ticking = true
                requestAnimationFrame(() => {
                    if (this.oldScrollTop > scrollTop) {
                        this.visible = true
                    } else if (scrollTop > 300 && this.visible) {
                        this.visible = false
                    } else if (scrollTop < 300 && !this.visible) {
                        this.visible = true
                    }
                    this.oldScrollTop = scrollTop
                    this.ticking = false
                })
            }
        },
        toggle() {
            this.$emit('toggle')
        },
    },
    beforeDestroy() {
        document.body.removeEventListener('scroll', this.handleScroll, true)
    },
}
