//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
 * author kcz
 * date 2019-11-20
 * description 修改多選、下拉、單選等控件options的組件，添加移除校驗規制的組件
 */
export default {
  name: 'KChangeOption',
  props: {
    value: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      default: 'option'
    }
  },
  methods: {
    handleAdd() {
      // 添加
      const addData = [
        ...this.value,
        {
          value: `${this.value.length + 1}`,
          label: '選項' + (this.value.length + 1),
          list: this.type === 'tab' ? [] : undefined
        }
      ]
      this.$emit('input', addData)
    },
    handleAddCol() {
      // 添加柵格Col
      const addData = [
        ...this.value,
        {
          span: 12,
          list: []
        }
      ]
      this.$emit('input', addData)
    },
    handleAddRules() {
      const addData = [
        ...this.value,
        {
          pattern: '',
          message: ''
        }
      ]
      this.$emit('input', addData)
    },
    handleDelete(deleteIndex) {
      // 刪除
      this.$emit(
        'input',
        this.value.filter((val, index) => index !== deleteIndex)
      )
    }
  }
}
