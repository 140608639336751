import { domTitle, setDocumentTitle } from '@/utils/domUtil';
export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome() {
  const arr = ['休息一會兒吧', '準備吃什麽呢?', '要不要打一把 LOL', '我猜妳可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 觸發 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader(callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () { }
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function isIE() {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

export const isMobile = () => {
  const userAgentInfo = navigator.userAgent;
  const Agents = ["Android", "iPhone",
    "SymbianOS", "Windows Phone",
    "iPad", "iPod"];
  return Agents.find(item => userAgentInfo.includes(item))
}
export const isIOS = () => {
  var u = navigator.userAgent;
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios終端
  return isiOS;
}


/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}

/**
 * 處理移動端title
 * @param route // 路由信息
 */
export const handleMobleDocTitle = (route) => {
  if (route) {
    const { name, query: { index } } = route
    if (name == 'mobileDocumentApproval') {
      if (index == 1) {
        setDocumentTitle(`已審公文-${domTitle}`)
      }
      if (index == 2) {
        setDocumentTitle(`已收公文-${domTitle}`)
      }
      if (index == 3) {
        setDocumentTitle(`文書歸檔-${domTitle}`)
      }
    }
  }
}