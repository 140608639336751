// eslint-disable-next-line
import {
  UserLayout,
  BasicLayout,
  RouteView,
  BlankLayout,
  PageView,
  UserLayoutWx,
} from '@/layouts'
import {
  bxAnaalyse
} from '@/core/icons'

import { mobileRouter } from './mobileTerminal.router.conf'
export const asyncRouterMap = [

  {
    path: '/',
    name: 'MenuIndex.vue',
    component: BasicLayout,
    meta: {
      title: '首頁'
    },
    redirect: '/dashboard/workplace',
    children: [
      // dashboard
      {
        path: 'dashboard',
        name: 'dashboard',
        redirect: '/dashboard/workplace',
        component: RouteView,
        // eslint-disable-next-line standard/object-curly-even-spacing
        meta: {
          title: '儀表盤',
          keepAlive: true,
          icon: bxAnaalyse /* permission: [ 'dashboard' ] */
        },
        children: [{
            path: 'analysis/:pageNo([1-9]\\d*)?',
            name: 'Analysis',
            component: () => import('@/views/system/dashboard/Analysis'),
            // eslint-disable-next-line standard/object-curly-even-spacing
            meta: {
              title: '分析頁',
              keepAlive: true /* permission: [ 'dashboard' ] */
            }
          },
          {
            path: 'workplace',
            name: 'Workplace',
            component: () => import('@/views/system/dashboard/Workplace'),
            // eslint-disable-next-line standard/object-curly-even-spacing
            meta: {
              title: '工作臺',
              keepAlive: false /*, permission: [ 'dashboard' ] */
            }
          }
        ]
      }
    ]
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

/**
 * 基礎路由
 * @type { *[] }
 */
export const constantRouterMap = [{
    path: '/userWx',
    component: UserLayoutWx,
    redirect: '/userWx/login',
    hidden: true,
    children: [{
      path: 'login',
      name: 'login',
      component: () => import( /* webpackChunkName: "user" */ '@/views/userLoginReg/Login')
    }]
  },
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/loginAdmin',
    hidden: true,
    children: [
      // 超級管理員頁面
      {
        path: 'loginAdmin',
        name: 'loginAdmin',
        component: () => import( /* webpackChunkName: "user" */ '@/views/userLoginReg/LoginAdmin')
      },
      {
        path: 'login_old',
        name: 'login_old',
        component: () => import( /* webpackChunkName: "user" */ '@/views/userLoginReg/LoginOld')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import( /* webpackChunkName: "user" */ '@/views/userLoginReg/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import( /* webpackChunkName: "user" */ '@/views/userLoginReg/RegisterResult')
      },

      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },

  ...mobileRouter,

  {
    path: '/404',
    component: () => import( /* webpackChunkName: "fail" */ '@/views/system/exception/404')
  },
  {
    path: '/scanLogin',
    component: () => import( /* webpackChunkName: "scanLogin" */ '@/views/fileStream/login/index')
  }

]