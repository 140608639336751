//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
 * author kcz
 * date 2019-11-20
 * description 導入json Modal
 */
import { codemirror } from 'vue-codemirror-lite'
import jsonFormat from '../config/jsonFormat'
export default {
  name: 'ImportJsonModal',
  data() {
    return {
      visible: false,
      jsonFormat,
      jsonData: {},
      handleSetSelectItem: null
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.jsonFormat = jsonFormat
      }
    }
  },
  components: {
    codemirror
  },
  computed: {
    editor() {
      // get current editor object
      return this.$refs.myEditor.editor
    }
  },
  methods: {
    handleCancel() {
      this.visible = false
    },
    beforeUpload(e) {
      // 通過json文件導入
      const _this = this
      const reader = new FileReader()
      reader.readAsText(e)
      reader.onload = function() {
        _this.jsonFormat = this.result
        _this.handleImportJson()
      }
      return false
    },
    handleImportJson() {
      // 導入JSON
      try {
        const editorJsonData = JSON.parse(this.jsonFormat)
        this.jsonData.list = editorJsonData.list
        this.jsonData.config = editorJsonData.config
        this.jsonData.config.layout = editorJsonData.config.layout
        this.handleCancel()
        // 導入之後，需要清除已選擇key
        this.handleSetSelectItem({ key: '' })

        this.$message.success('導入成功')
      } catch (error) {
        console.error(error)
        this.$message.error('導入失敗，數據格式不對')
      }
    }
  }
}
