//
//
//
//
//
//

export default {
    name: 'XCard',
  data() {
    return {
      tstyle: { 'padding-bottom': '0px', 'margin-bottom': '10px' }
    }
  }
}
