//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NoticeIcon from '@/components/NoticeIcon'
import { ALL_APPS_MENU } from '@/store/mutation-types'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { message } from 'ant-design-vue/es'
import screenfull from 'screenfull'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Help from './Help.vue'
import msgPop from './msgPop.vue'

export default {
    name: 'UserMenu',
    components: {
        NoticeIcon,
        screenfull,
        msgPop,
        OpenData,
        Help,
    },
    props: {
        mode: {
            type: String,
            // sidemenu, topmenu
            default: 'sidemenu',
        },
        onlyExit: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
            visible: false,
            popoverVisible: false,
            confirmLoading: false,
            form1: this.$form.createForm(this),
            defApp: [],
            isFullscreen: false,
            userId: '',
        }
    },
    watch: {
        popoverVisible: {
            handler() {
                this.$store.dispatch('msg/unReadNums')
            },
        },
    },

    mounted() {
        if (Vue.ls.get('WX_data_set')) {
            this.userId = Vue.ls.get('WX_data_set').userId
        }
        this.$store.dispatch('msg/unReadNums')
    },

    computed: {
        ...mapGetters(['nickname', 'avatar', 'userInfo']),
        unReadNum() {
            return this.$store.state.msg.unReadNum
        },
    },
    methods: {
        ...mapActions(['Logout', 'MenuChange']),
        hidePopover() {
            this.popoverVisible = false
        },

        formatUnReadNums(num) {
            if (num > 99) return '...'
            return num
        },

        handleLogout() {
            this.$confirm({
                title: '提示',
                content: '真的要註銷登錄嗎 ?',
                okText: '確定',
                cancelText: '取消',
                onOk: () => {
                    let fullPath = ''
                    const query = { ...this.$route.query }
                    const arr = ['appid', 'auth_code', 'state']
                    arr.forEach((a) => {
                        if (a in query) {
                            delete query[a]
                        }
                    })
                    const queryInfo = this.formatObj(query)
                    if (this.$route.name === 'Console') {
                        fullPath = '/fileStreamHome'
                    } else {
                        fullPath = `${this.$route.path}${queryInfo ? '?' : ''}${queryInfo}`
                    }
                    return this.Logout({})
                        .then(() => {
                            setTimeout(() => {
                                // window.open('/wxLogin.html','_self')
                                // window.location.reload('')
                                console.log(this.$route)
                                this.$router.push({
                                    name: 'login',
                                    query: {
                                        redirect: encodeURIComponent(fullPath),
                                    },
                                })
                            }, 16)
                        })
                        .catch((err) => {
                            this.$message.error({
                                title: '錯誤',
                                description: err.message,
                            })
                        })
                },
                onCancel() {},
            })
        },
        formatObj(obj) {
            let str = ''
            for (const key in obj) {
                if (Object.hasOwnProperty.call(obj, key)) {
                    const element = obj[key]
                    str += `&${key}=${element}`
                }
            }
            return str.substring(1)
        },

        /**
         * 打開切換應用框
         */
        appToggled() {
            this.visible = true
            this.defApp.push(Vue.ls.get(ALL_APPS_MENU)[0].code)
        },

        switchApp(appCode) {
            this.visible = false
            this.defApp = []
            const applicationData = this.userInfo.apps.filter((item) => item.code === appCode)
            const hideMessage = message.loading('正在切換應用！', 0)
            this.MenuChange(applicationData[0])
                .then((res) => {
                    hideMessage()
                    // eslint-disable-next-line handle-callback-err
                })
                .catch((err) => {
                    message.error('應用切換異常')
                })
        },
        handleCancel() {
            this.form1.resetFields()
            this.visible = false
        },
        /* 全屏切換 */
        toggleFullscreen() {
            if (!screenfull.isEnabled) {
                message.error('您的瀏覽器不支持全屏模式')
                return
            }
            screenfull.toggle()
            if (screenfull.isFullscreen) {
                this.isFullscreen = false
            } else {
                this.isFullscreen = true
            }
        },
    },
}
