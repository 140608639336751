/*
 * @Description: 組件輸出文件,不打包antd ui組件
 * @Author: kcz
 * @Date: 2020-01-02 22:41:48
 * @LastEditors: kcz
 * @LastEditTime: 2021-05-14 19:09:25
 */

// 解決Chrome控制臺non-passive event listener輸出問題
// import "default-passive-events";
// 導入樣式
import '../styles/form-design.less'

// 導出本地iconfont
import '../static/icons/iconfont'

// 導入單個組件
import KFormDesign from './KFormDesign/index'
import KFormPreview from './KFormPreview/index'
import KFormBuild from './KFormBuild/index'
import KFormItem from './KFormItem/index'
import {
  customComponents,
  basicsList
} from './KFormDesign/config/formItemsConfig'

const components = [KFormDesign, KFormBuild, KFormItem, KFormPreview]

const install = function(Vue) {
  // use ant組件
  if (install.installed) return
  install.installed = true

  components.map(component => {
    Vue.component(component.name, component)
  })
}

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}

/**
 * @Author: kcz
 * @description: 配置組件及添加自定義組件
 * @param {json}
 * @return: Boolean
 */
function setFormDesignConfig(config) {
  if (!config || typeof config !== 'object') {
    console.error('傳入config的參數必須為對象')
    return false
  }
  try {
    customComponents.title = config.title || '自義定組件'
    customComponents.list = config.list || []
    // 將自定義組件列表綁到window.$customComponentList上
    window.$customComponentList = config.list || []
    // uploadFile 配置 start
    // 配置uploadFile默認上傳地址
    const uploadFile = basicsList.filter(item => item.type === 'uploadFile')[0]
    uploadFile.options.action =
      config.uploadFile || 'http://cdn.kcz66.com/uploadFile.txt'

    // 配置uploadFile默認額外參數
    uploadFile.options.data = JSON.stringify(config.uploadFileData || {})

    // 配置uploadFile默認name
    uploadFile.options.fileName = config.uploadFileName || 'file'
    // 配置uploadFile默認headers
    uploadFile.options.headers = config.uploadFileHeaders || {}
    // uploadFile 配置 end

    // uploadImage配置 start
    // 配置uploadImage默認上傳地址
    const uploadImg = basicsList.filter(item => item.type === 'uploadImg')[0]
    uploadImg.options.action =
      config.uploadImage || 'http://cdn.kcz66.com/upload-img.txt'
    // 配置uploadImage默認額外參數
    uploadImg.options.data = JSON.stringify(config.uploadImageData || {})
    // 配置uploadFile默認name
    uploadImg.options.fileName = config.uploadImageName || 'image'
    // 配置uploadFile默認headers
    uploadImg.options.headers = config.uploadImageHeaders || {}
    // uploadImage配置 end

    return true
  } catch (err) {
    console.error(err)
    return false
  }
}

/**
 * @author lizhichao<meteoroc@outlook.com>
 * @description 配置k-form-build(預覽時)，暫只支持dynamicData的設置
 * @param { object }config
 */
function setFormBuildConfig(config) {
  if (!config || typeof config !== 'object') {
    console.error('傳入setFormBuildConfig的參數必須為對象')
    return false
  }
  if (config.dynamicData) {
    window.$kfb_dynamicData = config.dynamicData
  }
}

// 這裏可以用es6的解構語法導入組件
export {
  install,
  KFormDesign,
  KFormBuild,
  KFormItem,
  KFormPreview,
  setFormDesignConfig,
  setFormBuildConfig
}

// 這裏默認導入全部組件
export default {
  install,
  setConfig: setFormDesignConfig,
  setFormDesignConfig: setFormDesignConfig,
  setFormBuildConfig: setFormBuildConfig
}
