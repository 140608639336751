/**
 * 項目默認配置項
 * primaryColor - 默認主題色, 如果修改顏色不生效，請清理 localStorage
 * navTheme - sidebar theme ['dark', 'light'] 兩種主題
 * colorWeak - 色盲模式
 * layout - 整體布局方式 ['sidemenu', 'topmenu'] 兩種布局
 * fixedHeader - 固定 Header : boolean
 * fixSiderbar - 固定左側菜單欄 ： boolean
 * autoHideHeader - 向下滾動時，隱藏 Header : boolean
 * contentWidth - 內容區布局： 流式 |  固定
 *
 * storageOptions: {} - Vue-ls 插件配置項 (localStorage/sessionStorage)
 * production: 變量暫先設定為 false，目的是各種環境都正常顯示設置抽屜，真實環境請放開註釋
 *
 *
 */

 export default {
  primaryColor: '#1890ff', // primary color of ant design
  navTheme: 'light', // theme for nav menu light/dark
  layout: 'topmenu', // nav menu position: sidemenu or topmenu
  contentWidth: 'Fixed', // layout of content: Fluid or Fixed, only works when layout is topmenu
  fixedHeader: false, // sticky header
  fixSiderbar: false, // sticky siderbar
  autoHideHeader: false, //  auto hide header
  colorWeak: false,
  multiTab: false,
  production: process.env.NODE_ENV === 'production' && process.env.VUE_APP_PREVIEW !== 'true',
  // vue-ls options
  storageOptions: {
    namespace: 'pro__',
    name: 'ls',
    storage: 'session',
  }
}
