//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
 * author kcz
 * date 2019-11-20
 * description 表單控件屬性設置組件,因為配置數據是引用關系，所以可以直接修改
 */
import KChangeOption from '../../KChangeOption/index.vue'
import kCheckbox from '../../KCheckbox/index.vue'
export default {
  name: 'FormItemProperties',
  data() {
    return {
      // 緩存中的全部字典
      dictDataAll: this.$options.filters['dictDataAll'](),
      familyOptions: [
        // 字體選擇設置
        {
          value: 'SimSun',
          label: '宋體'
        },
        {
          value: 'FangSong',
          label: '仿宋'
        },
        {
          value: 'SimHei',
          label: '黑體'
        },
        {
          value: 'PingFangSC-Regular',
          label: '蘋方'
        },
        {
          value: 'KaiTi',
          label: '楷體'
        },
        {
          value: 'LiSu',
          label: '隸書'
        }
      ],
      sizeOptions: [
        // 字號選擇設置
        {
          value: '26pt',
          label: '一號'
        },
        {
          value: '24pt',
          label: '小一'
        },
        {
          value: '22pt',
          label: '二號'
        },
        {
          value: '18pt',
          label: '小二'
        },
        {
          value: '16pt',
          label: '三號'
        },
        {
          value: '15pt',
          label: '小三'
        },
        {
          value: '14pt',
          label: '四號'
        },
        {
          value: '12pt',
          label: '小四'
        },
        {
          value: '10.5pt',
          label: '五號'
        },
        {
          value: '9pt',
          label: '小五'
        }
      ]
    }
  },
  computed: {
    options() {
      return this.selectItem.options || {}
    }
  },
  methods: {
    // 下拉框選擇動態數據
    aSelectChange (value) {
      const param = []
      this.$options.filters['dictData'](value).forEach(item => {
        const paramItem = {}
        paramItem.label = item.name
        paramItem.value = item.code
        param.push(paramItem)
      })
      this.options.options = param
    }
  },
  props: {
    selectItem: {
      type: Object,
      required: true
    },
    hideModel: {
      type: Boolean,
      default: false
    }
  },
  components: {
    KChangeOption,
    kCheckbox
  }
}
