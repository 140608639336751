//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import draggable from 'vuedraggable'

export default {
  props: {
    columns: {
      type: Array,
      default: () => ([])
    }
  },
  components: {
    draggable
  },
  data() {
    return {
      indeterminate: false,
      checkAll: true,
      columnsSetting: [],
      originColumns: []
    }
  },
  methods: {
    reset() {
      this.columnsSetting = JSON.parse(JSON.stringify(this.originColumns))
      this.indeterminate = false
      this.checkAll = true
      this.emitColumnChange()
    },
    onChange() {
      const checkedList = this.columnsSetting.filter(value => value.checked)
      this.indeterminate = !!checkedList.length && checkedList.length < this.columnsSetting.length
      this.checkAll = checkedList.length === this.columnsSetting.length
      this.emitColumnChange()
    },
    onCheckAllChange(e) {
      const val = e.target.checked
      Object.assign(this, {
        indeterminate: false,
        checkAll: val,
        columnsSetting: this.columns.map(value => ({ ...value, checked: val }))
      })
      this.emitColumnChange()
    },
    emitColumnChange() {
      this.$emit('columnChange', this.columnsSetting)
    }
  },
  mounted() {
    this.columnsSetting = this.columns.map(value => ({ ...value, checked: true }))
    this.originColumns = JSON.parse(JSON.stringify(this.columnsSetting))
  }
}
