//
//
//
//
//
//

export default {
  name: 'svgClass',
  props: {
    //  使用icon的圖標類型
    type: {
      type: String,
      required: true,
    },
    // 是否添加class樣式
    className: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconClass() {
      return `#icon-${this.type}`
    },
    svgClass() {
      if (this.className) {
        return `icon ${this.className}`
      } else {
        return `icon`
      }
    },
  },
}
