//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { message } from 'ant-design-vue'
import vueEsign from './vueEsign.vue'
export default {
  components: {
    vueEsign,
    message,
  },
  data() {
    return {
      visible: false,
      resultImg: '',
      isCrop: false,
      lineWidth: 6,
      lineColor: '#000000',
      bgColor: '',
    }
  },
  created() {},
  methods: {
    show({ signature }) {
      this.visible = true
      if (signature) {
        this.resultImg = signature
        this.$refs.esign?.reset()
      }
    },
    //清空值
    handleReset() {
      this.$refs.esign.reset()
      this.resultImg = ''
    },
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then((res) => {
          this.resultImg = res
        })
        .catch(() => {
          message.warning('無任何簽字')
        })
    },
    handleClear() {
      this.visible = false
    },
    handleOk() {
      this.$refs.esign
        .generate()
        .then((res) => {
          this.$emit('successful', res)
          this.handleClear()
        })
        .catch(() => {
          message.warning('無任何簽字')
        })
    },
  },
}
