//
//
//
//
//
//

import WangEditor from 'wangeditor'
export default {
  name: 'ComponentWangeditor',
  data () {
    return {
      edit: ''
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default: () => {
        return {}
      }
    },
    uploadConfig: {
      type: Object,
      default: () => {
        return {
          method: 'http', // 支持custom(objurl)和http(服務器)和base64
          url: '/'
        }
      }
    }
  },
  computed: {
    customConfig () {
      return {
        pasteFilterStyle: false, // 關閉掉粘貼樣式的過濾
        pasteIgnoreImg: false, // 粘貼時不忽略圖片
        ...this.config
      }
    }
  },
  watch: {

  },
  components: {

  },
  methods: {
    readBlobAsDataURL (blob, callback) {
      var a = new FileReader()
      a.onload = function (e) { callback(e.target.result) }
      a.readAsDataURL(blob)
    },
    initEditor () {
      var self = this
      this.editor = new WangEditor(this.$refs.myEditor)
      // 配置 onchange 事件
      this.editor.customConfig = this.customConfig
      this.editor.customConfig.uploadImgMaxLength = 5
      this.editor.change = function () { // 編輯區域內容變化時
        self.$emit('input', this.txt.html())
        self.$emit('onchange', this.txt.html(), this.txt)
        // editor.txt.html('.....') //設置編輯器內容
        // editor.txt.clear() //清空編輯器內容
        // editor.txt.append('<p>追加的內容</p>')//繼續追加內容。
        // editor.txt.text()  // 讀取 text
        // editor.txt.getJSON()  // 獲取 JSON 格式的內容
      }
      this.editor.customConfig.customUploadImg = function (files, insert) {
        if (self.uploadConfig.method === 'custom') {
          files.forEach(file => {
            var fileUrl = URL.createObjectURL(file)
            insert(fileUrl)
          })
        }
        if (self.uploadConfig.method === 'base64') {
          files.forEach(file => {
            self.readBlobAsDataURL(file, function (dataurl) {
              insert(dataurl)
            })
          })
        }
        if (self.uploadConfig.method === 'http') {
          if (self.uploadConfig.callback) {
            self.uploadConfig.callback(files, insert)
          } else {
            var formData = new FormData()
            files.forEach(file => {
              formData.append('file', file)
            })
            self.$axios.post(self.uploadConfig.url, formData).then(({ data }) => {
              if (data.status === 'success') {
                insert(data.url)
              }
            })
          }
        }
      }

      this.editor.create() // 生成編輯器
      this.editor.txt.text(this.value) // 生成編輯器
      this.$emit('oninit', this.editor)
    }
  },
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
    this.initEditor()
  }
}
