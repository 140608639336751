//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dialogMsg from './dialogMsg.vue'
import msgList from './msgList.vue'
export default {
    components: {
        msgList,
        dialogMsg,
    },
    data() {
        return {
            isClear: false,
            isUnRead: false,
        }
    },
    computed: {
        unReadNum() {
            return this.$store.state.msg.unReadNum
        },
    },
    methods: {
        handleChange(evt) {
            this.isUnRead = evt === 2
        },
        close() {
            this.$emit('close')
            this.isClear = false
        },
        clearUnRead() {
            this.isClear = true
            this.$store.dispatch('msg/noticeFlush').then((res) => {
                if (res.code === 200) {
                    this.$message.success(res.message)
                    this.$refs.hasUnreadChecking && this.$refs.hasUnreadChecking.refresh()
                    this.$refs.hasUnreadReceive && this.$refs.hasUnreadReceive.refresh()
                    this.$refs.hasUnreadSending && this.$refs.hasUnreadSending.refresh()
                }
            })
        },
        showHistroy() {
            this.$refs.dialogMsg.open()
        },
    },
}
