//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    noticeType: {
      type: String,
      default: '',
    },
    isUnRead: {
      type: Boolean,
      default: false,
    },
    isClearMsg: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  watch: {
    isUnRead: {
      handler() {
        this.pageNo = 1
        this.msgList = []
        this.query()
      },
    },
    noticeType: {
      handler() {
        this.pageNo = 1
        this.msgList = []
        this.query()
      },
      immediate: true,
    },
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      msgList: [],
      checkedMsgList: [],
    }
  },
  computed: {
    plainMsgOptions() {
      return this.msgList.map((item) => item.id)
    },
  },
  methods: {
    handleClick({ id, url }) {
      if (this.isClearMsg) {
        return
      }
      this.$store.dispatch('msg/noticeUpdate', { id }).then((res) => {
        if (res.code === 200) {
          this.$store.dispatch('msg/unReadNums')
        }
      })
      const urlObj = this.parseURL(url)
      this.$router.push({
        path: urlObj.pathname,
        query: urlObj.search,
      })
      // this.$emit('close')
    },
    parseURL(href) {
      const url = new URL(href)
      let params = new URLSearchParams(url.searchParams)
      const obj = {}
      params = Object.fromEntries(params)
      obj.protocol = url.protocol
      obj.hostname = url.hostname
      obj.port = url.port
      obj.pathname = url.pathname
      obj.search = params
      obj.hash = url.hash
      return obj
    },
    judgeIsHistory() {
      return ['dialogApproval', 'dialogAccept', 'dialogSend'].includes(this.config.key)
    },
    query() {
      this.$store
        .dispatch('msg/query', {
          type: this.config.type,
          noticeType: this.config.index,
          isUnRead: this.isUnRead,
          pageNo: this.pageNo,
          isHistory: this.judgeIsHistory(),
          pageSize: this.pageSize,
        })
        .then((res) => {
          if (res.code === 200) {
            this.msgList = [...res.data.rows]
            this.clearSelectMsgList()
            this.$emit('pageConfig', {
              totalPage: res.data.totalPage,
              isLoading: false,
              totalRows: res.data.totalRows,
              msgListLength: this.msgList.length,
            })
          }
        })
    },
    refresh() {
      this.msgList = []
      this.pageNo = 1
      this.query()
    },
    changePage({ pageNo, pageSize }) {
      this.pageNo = pageNo
      if (pageSize) this.pageSize = pageSize
      this.query()
    },
    changeMsg(item) {
      this.$emit('changeMsgselectStatus', {
        checkAll: false,
        indeterminate: this.checkedMsgList?.length > 0 ? true : false,
        checkedMsgList: this.checkedMsgList,
      })
    },
    selectAll(e) {
      const { target } = e
      if (target) {
        this.checkedMsgList = target?.checked ? this.plainMsgOptions : []
        this.$emit('changeMsgselectStatus', {
          checkAll: target?.checked || false,
          indeterminate: false,
          checkedMsgList: this.checkedMsgList,
        })
      }
    },
    // 清空已勾選的數據
    clearSelectMsgList() {
      this.checkedMsgList = []
      this.$emit('changeMsgselectStatus', {
        checkAll: false,
        indeterminate: false,
        checkedMsgList: [],
      })
    },
  },
}
