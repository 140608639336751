//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const resultEnum = ['success', 'error']

export default {
  name: 'Result',
  props: {
    /** @Deprecated */
    isSuccess: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: resultEnum[0],
      validator (val) {
        return (val) => resultEnum.includes(val)
      }
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },
  computed: {
    localIsSuccess: function () {
      return this.type === resultEnum[0]
    }
  }
}
