//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
 * author kcz
 * date 2019-12-31
 * description 上傳文件組件
 */
export default {
  name: 'KUploadFile',
  // eslint-disable-next-line vue/require-prop-types
  props: ['record', 'value', 'config', 'parentDisabled', 'dynamicData'],
  data() {
    return {
      fileList: []
    }
  },
  watch: {
    value: {
      // value 需要深度監聽及默認先執行handler函數
      handler(val) {
        if (val) {
          this.setFileList()
        }
      },
      immediate: true,
      deep: true
    }
  },

  computed: {
    optionsData() {
      try {
        return JSON.parse(this.record.options.data)
      } catch (err) {
        console.error(err)
        return {}
      }
    }
  },
  methods: {
    setFileList() {
      // 當傳入value改變時，fileList也要改變
      // 如果傳入的值為字符串，則轉成json
      if (typeof this.value === 'string') {
        this.fileList = JSON.parse(this.value)
        // 將轉好的json覆蓋組件默認值的字符串
        this.handleSelectChange()
      } else {
        this.fileList = this.value
      }
    },
    handleSelectChange() {
      setTimeout(() => {
        const arr = this.fileList.map(item => {
          if (typeof item.response !== 'undefined') {
            const res = item.response
            return {
              type: 'file',
              name: item.name,
              status: item.status,
              uid: res.data.fileId || Date.now(),
              url: res.data.url || ''
            }
          } else {
            return {
              type: 'file',
              name: item.name,
              status: item.status,
              uid: item.uid,
              url: item.url || ''
            }
          }
        })

        this.$emit('change', arr)
        this.$emit('input', arr)
      }, 10)
    },
    handlePreview(file) {
      // 下載文件
      const downloadWay = this.record.options.downloadWay
      const dynamicFun = this.record.options.dynamicFun
      if (downloadWay === 'a') {
        // 使用a標簽下載
        const a = document.createElement('a')
        a.href = file.url || file.thumbUrl
        a.download = file.name
        a.click()
      } else if (downloadWay === 'ajax') {
        // 使用ajax獲取文件blob，並保持到本地
        this.getBlob(file.url || file.thumbUrl).then(blob => {
          this.saveAs(blob, file.name)
        })
      } else if (downloadWay === 'dynamic') {
        // 觸發動態函數
        this.dynamicData[dynamicFun](file)
      }
    },
    /**
     * 獲取 blob
     * url 目標文件地址
     */
    getBlob(url) {
      return new Promise(resolve => {
        const xhr = new XMLHttpRequest()

        xhr.open('GET', url, true)
        xhr.responseType = 'blob'
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response)
          }
        }

        xhr.send()
      })
    },
    /**
     * 保存 blob
     * filename 想要保存的文件名稱
     */
    saveAs(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename)
      } else {
        const link = document.createElement('a')
        const body = document.querySelector('body')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename

        // fix Firefox
        link.style.display = 'none'
        body.appendChild(link)

        link.click()
        body.removeChild(link)

        window.URL.revokeObjectURL(link.href)
      }
    },
    remove() {
      this.handleSelectChange()
    },
    beforeUpload(e, files) {
      if (files.length + this.fileList.length > this.record.options.limit) {
        this.$message.warning(`最大上傳數量為${this.record.options.limit}`)
        files.splice(this.record.options.limit - this.fileList.length)
      }
    },
    handleChange(info) {
      this.fileList = info.fileList
      if (info.file.status === 'done') {
        const res = info.file.response
        if (res.code === 0) {
          this.handleSelectChange()
        } else {
          this.fileList.pop()
          this.$message.error(`文件上傳失敗`)
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`文件上傳失敗`)
      }
    }
  }
}
