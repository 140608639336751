
import { BasicLayout, BlankLayout, Iframe, PageView, RouteView } from '@/layouts'

// 前端路由表
const constantRouterComponents = {
  // 基礎頁面 layout 必須引入
  BasicLayout: BasicLayout,
  BlankLayout: BlankLayout,
  RouteView: RouteView,
  PageView: PageView,
  Iframe: Iframe,
  '403': () => import('@/views/system/exception/403'),
  '404': () => import('@/views/system/exception/404'),
  '500': () => import('@/views/system/exception/500'),

  'Workplace': () => import('@/views/system/dashboard/Workplace'),
  // account
  'AccountCenter': () => import('@/views/system/account/center/Index'),
  'AccountSettings': () => import('@/views/system/account/settings/Index'),
  'BaseSettings': () => import('@/views/system/account/settings/BaseSetting'),
  'UserCenter':() => import('@/views/system/account/userCenter/index'),
  'SecuritySettings': () => import('@/views/system/account/settings/Security'),
  'CustomSettings': () => import('@/views/system/account/settings/Custom'),
  'BindingSettings': () => import('@/views/system/account/settings/Binding'),
  'NotificationSettings': () => import('@/views/system/account/settings/Notification'),

  // 默認首頁
  'Console': () => import('@/views/fileStream/home/index.vue'),

  // editor 測試頁面
  'editorCeshi': () => import('@/views/editorCeshi/index.vue')
}

// 前端未找到頁面路由（固定不用改）、原來為 /404
const notFoundRouter = {
  path: '*', redirect: '/fileStreamHome', hidden: true
}
// 個人中心頁面
const userAccount = [
  // account
  {
    'name': 'account',
    'pid': 0,
    'id': 10028,
    'meta': {
      'title': '個人頁',
      'icon': 'user',
      'show': false
    },
    'redirect': '/account/center',
    'component': 'RouteView'
  },
  {
    'name': 'center',
    'pid': 10028,
    'id': 10029,
    'meta': {
      'title': '個人中心',
      'show': false
    },
    'component': 'AccountCenter'
  },
  // 特殊三級菜單
  {
    'name': 'settings',
    'pid': '10028',
    'id': '10030',
    'meta': {
      'title': '個人設置',
      'hideHeader': true,
      'hideChildren': true,
      'show': false
    },
    'redirect': '/account/settings/base',
    'component': 'UserCenter'
  },
  {
    'name': 'BaseSettings',
    'path': '/account/settings/base',
    'pid': 10030,
    'id': 10031,
    'meta': {
      'title': '基本設置',
      'show': false
    },
    'component': 'BaseSettings'
  },
  {
    'name': 'SecuritySettings',
    'path': '/account/settings/security',
    'pid': 10030,
    'id': 10032,
    'meta': {
      'title': '安全設置',
      'show': false
    },
    'component': 'SecuritySettings'
  },
  {
    'name': 'CustomSettings',
    'path': '/account/settings/custom',
    'pid': 10030,
    'id': 10033,
    'meta': {
      'title': '個性化設置',
      'show': false
    },
    'component': 'CustomSettings'
  },
  {
    'name': 'BindingSettings',
    'path': '/account/settings/binding',
    'pid': 10030,
    'id': 10034,
    'meta': {
      'title': '賬戶綁定',
      'show': false
    },
    'component': 'BindingSettings'
  },
  {
    'name': 'NotificationSettings',
    'path': '/account/settings/notification',
    'pid': 10030,
    'id': 10034,
    'meta': {
      'title': '新消息通知',
      'show': false
    },
    'component': 'NotificationSettings'
  },
  {
    'name': 'Console',
    'path': '/welcome',
    'pid': 0,
    'id': 183183,
    'meta': {
      'title': '首頁',
      'show': false
    },
    'component': 'Console'
  },
  {
    'name': 'editorCeshi',
    'path': '/editorCeshi',
    'pid': -1,
    'id': 183183111,
    'meta': {
      'title': 'editor測試',
      'show': true
    },
    'component': 'editorCeshi'
  }
]

// 根級菜單
const rootRouter = {
  key: '',
  name: 'MenuIndex.vue',
  path: '',
  component: 'BasicLayout',
  redirect: '/welcome',
  meta: {
    title: '首頁'
  },
  children: []
}

/**
 * 動態生成菜單
 * @param data
 * @returns {Promise<Router>}
 */
export const generatorDynamicRouter = (data) => {
  return new Promise((resolve, reject) => {
    const resNav = data.antDesignmenus
    const menuNav = []
    const childrenNav = []
    //      後端數據, 根級樹數組,  根級 PID
    listToTree(resNav, childrenNav, 0)

    /**
     * 增加靜態網頁
     */
    listToTree(userAccount, childrenNav, 0)
    rootRouter.children = childrenNav
    menuNav.push(rootRouter)
    const routers = generator(menuNav)
    routers.push(notFoundRouter)
    resolve(routers)
  }).catch(err => {
    // reject('加載菜單失敗')
    return Promise.reject(err)
  })
}

/**
 * 格式化樹形結構數據 生成 vue-router 層級路由表
 *
 * @param routerMap
 * @param parent
 * @returns {*}
 */
export const generator = (routerMap, parent) => {
  return routerMap.map(item => {
    // eslint-disable-next-line no-unused-vars
    const { title, show, hideChildren, hiddenHeaderContent, target, icon, link } = item.meta || {}
    const currentRouter = {
      // 如果路由設置了 path，則作為默認 path，否則 路由地址 動態拼接生成如 /dashboard/workplace
      path: item.path || `${parent && parent.path || ''}/${item.key}`,
      // 路由名稱，建議唯一
      name: item.name || item.key || '',
      // 該路由對應頁面的 組件 :方案1
      // component: constantRouterComponents[item.component || item.key],
      // 該路由對應頁面的 組件 :方案2 (動態加載)
      component: (constantRouterComponents[item.component || item.key]) || (() => import(`@/views/${item.component}`)),
      // meta: 頁面標題, 菜單圖標, 頁面權限(供指令權限用，可去掉)
      meta: {
        title: title,
        icon: icon || undefined,
        // hiddenHeaderContent: hiddenHeaderContent,
        target: target,
        link: link
      }
    }
    // 是否設置了隱藏菜單
    if (show === false) {
      currentRouter.hidden = true
    }
    // 是否設置了隱藏子菜單
    if (hideChildren) {
      currentRouter.hideChildrenInMenu = true
    }
    // 為了防止出現後端返回結果不規範，處理有可能出現拼接出兩個 反斜杠
    if (!currentRouter.path.startsWith('http')) {
      currentRouter.path = currentRouter.path.replace('//', '/')
    }
    // 重定向
    item.redirect && (currentRouter.redirect = item.redirect)
    // 是否有子菜單，並遞歸處理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children, currentRouter)
    }
    return currentRouter
  })
}

/**
 * 數組轉樹形結構
 * @param list 源數組
 * @param tree 樹
 * @param parentId 父ID
 */
const listToTree = (list, tree, parentId) => {
  list.forEach(item => {
    // 判斷是否為父級菜單
    // eslint-disable-next-line eqeqeq
    if (item.pid == parentId) {
      const child = {
        ...item,
        key: item.key || item.name,
        children: []
      }
      // 叠代 list， 找到當前菜單相符合的所有子菜單
      listToTree(list, child.children, item.id)
      // 刪掉不存在 children 值的屬性
      if (child.children.length <= 0) {
        delete child.children
      }
      // 加入到樹中
      tree.push(child)
    }
  })
}
