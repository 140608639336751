export const canPreview = ['.pptx', '.ppt', '.pot', '.potx', '.pps', '.ppsx',
'.dps', '.dpt', '.pptm', '.potm', '.ppsm', '.doc', '.dot', '.wps', '.wpt', '.docx',
'.dotx', '.docm', '.dotm', '.xls', '.xlt', '.et', '.ett', '.xlsx', '.xltx',
'.csv', 'xlsb', 'xlsm', '.xltm', '.ets', '.pdf', '.lrc', '.c', '.cpp', '.h', '.asm', '.s',
'.java', '.asp', '.bat', '.bas', '.prg', '.cmd', '.rtf',
 '.txt', '.log', '.xml', '.htm', '.html']
 export const images = ['.png', '.jpg', '.jpeg']

// export const serverUrl = 'edu.yimitech.com.cn'
//  export const serverUrl = 'document.dekopon.cn'
 export const serverUrl = 'e-xy.cc'
