/*
 * author kcz
 * date 2019-11-20
 * description 表單控件項
 */
// 基礎控件
export const basicsList = [
  {
    type: 'input', // 表單類型
    label: '輸入框', // 標題文字
    icon: 'icon-write',
    options: {
      type: 'text',
      width: '100%', // 寬度
      defaultValue: '', // 默認值
      placeholder: '請輸入', // 沒有輸入時，提示文字
      clearable: false,
      maxLength: null,
      addonBefore: '',
      addonAfter: '',
      hidden: false, // 是否隱藏，false顯示，true隱藏
      disabled: false // 是否禁用，false不禁用，true禁用
    },
    model: '', // 數據字段
    key: '',
    help: '',
    rules: [
      // 驗證規則
      {
        required: false, // 必須填寫
        message: '必填項'
      }
    ]
  },
  {
    type: 'textarea', // 表單類型
    label: '文本框', // 標題文字
    icon: 'icon-edit',
    options: {
      width: '100%', // 寬度
      minRows: 4,
      maxRows: 6,
      maxLength: null,
      defaultValue: '',
      clearable: false,
      hidden: false, // 是否隱藏，false顯示，true隱藏
      disabled: false,
      placeholder: '請輸入'
    },
    model: '', // 數據字段
    key: '',
    help: '',
    rules: [
      {
        required: false,
        message: '必填項'
      }
    ]
  },
  {
    type: 'number', // 表單類型
    label: '數字輸入框', // 標題文字
    icon: 'icon-number',
    options: {
      width: '100%', // 寬度
      defaultValue: 0, // 默認值
      min: null, // 可輸入最小值
      max: null, // 可輸入最大值
      precision: null,
      step: 1, // 步長，點擊加減按鈕時候，加減多少
      hidden: false, // 是否隱藏，false顯示，true隱藏
      disabled: false, // 是否禁用
      placeholder: '請輸入'
    },
    model: '', // 數據字段
    key: '',
    help: '',
    rules: [
      {
        required: false,
        message: '必填項'
      }
    ]
  },
  {
    type: 'select', // 表單類型
    label: '下拉選擇器', // 標題文字
    icon: 'icon-xiala',
    options: {
      width: '100%', // 寬度
      defaultValue: undefined, // 下拉選框請使用undefined為默認值
      multiple: false, // 是否允許多選
      disabled: false, // 是否禁用
      clearable: false, // 是否顯示清除按鈕
      hidden: false, // 是否隱藏，false顯示，true隱藏
      placeholder: '請選擇', // 默認提示文字
      dynamicKey: '',
      dynamic: false,
      options: [
        // 下拉選擇項配置
        {
          value: '1',
          label: '下拉框1'
        },
        {
          value: '2',
          label: '下拉框2'
        }
      ],
      showSearch: false // 是否顯示搜索框，搜索選擇的項的值，而不是文字
    },
    model: '',
    key: '',
    help: '',
    rules: [
      {
        required: false,
        message: '必填項'
      }
    ]
  },
  {
    type: 'checkbox',
    label: '多選框',
    icon: 'icon-duoxuan1',
    options: {
      disabled: false, // 是否禁用
      hidden: false, // 是否隱藏，false顯示，true隱藏
      defaultValue: [],
      dynamicKey: '',
      dynamic: false,
      options: [
        {
          value: '1',
          label: '選項1'
        },
        {
          value: '2',
          label: '選項2'
        },
        {
          value: '3',
          label: '選項3'
        }
      ]
    },
    model: '',
    key: '',
    help: '',
    rules: [
      {
        required: false,
        message: '必填項'
      }
    ]
  },
  {
    type: 'radio', // 表單類型
    label: '單選框', // 標題文字
    icon: 'icon-danxuan-cuxiantiao',
    options: {
      disabled: false, // 是否禁用
      hidden: false, // 是否隱藏，false顯示，true隱藏
      defaultValue: '', // 默認值
      dynamicKey: '',
      dynamic: false,
      options: [
        {
          value: '1',
          label: '選項1'
        },
        {
          value: '2',
          label: '選項2'
        },
        {
          value: '3',
          label: '選項3'
        }
      ]
    },
    model: '',
    key: '',
    help: '',
    rules: [
      {
        required: false,
        message: '必填項'
      }
    ]
  },
  {
    type: 'date', // 表單類型
    label: '日期選擇框', // 標題文字
    icon: 'icon-calendar',
    options: {
      width: '100%', // 寬度
      defaultValue: '', // 默認值，字符串 12:00:00
      rangeDefaultValue: [], // 默認值，字符串 12:00:00
      range: false, // 範圍日期選擇，為true則會顯示兩個時間選擇框（同時defaultValue和placeholder要改成數組），
      showTime: false, // 是否顯示時間選擇器
      disabled: false, // 是否禁用
      hidden: false, // 是否隱藏，false顯示，true隱藏
      clearable: false, // 是否顯示清除按鈕
      placeholder: '請選擇',
      rangePlaceholder: ['開始時間', '結束時間'],
      format: 'YYYY-MM-DD' // 展示格式  （請按照這個規則寫 YYYY-MM-DD HH:mm:ss，區分大小寫）
    },
    model: '',
    key: '',
    help: '',
    rules: [
      {
        required: false,
        message: '必填項'
      }
    ]
  },
  {
    type: 'time', // 表單類型
    label: '時間選擇框', // 標題文字
    icon: 'icon-time',
    options: {
      width: '100%', // 寬度
      defaultValue: '', // 默認值，字符串 12:00:00
      disabled: false, // 是否禁用
      hidden: false, // 是否隱藏，false顯示，true隱藏
      clearable: false, // 是否顯示清除按鈕
      placeholder: '請選擇',
      format: 'HH:mm:ss' // 展示格式
    },
    model: '',
    key: '',
    help: '',
    rules: [
      {
        required: false,
        message: '必填項'
      }
    ]
  },
  {
    type: 'rate', // 表單類型
    label: '評分', // 標題文字
    icon: 'icon-pingfen_moren',
    options: {
      defaultValue: 0,
      max: 5, // 最大值
      disabled: false, // 是否禁用
      hidden: false, // 是否隱藏，false顯示，true隱藏
      allowHalf: false // 是否允許半選
    },
    model: '',
    key: '',
    help: '',
    rules: [
      {
        required: false,
        message: '必填項'
      }
    ]
  },
  {
    type: 'slider', // 表單類型
    label: '滑動輸入條', // 標題文字
    icon: 'icon-menu',
    options: {
      width: '100%', // 寬度
      defaultValue: 0, // 默認值， 如果range為true的時候，則需要改成數組,如：[12,15]
      disabled: false, // 是否禁用
      hidden: false, // 是否隱藏，false顯示，true隱藏
      min: 0, // 最小值
      max: 100, // 最大值
      step: 1, // 步長，取值必須大於 0，並且可被 (max - min) 整除
      showInput: false // 是否顯示輸入框，range為true時，請勿開啟
      // range: false // 雙滑塊模式
    },
    model: '',
    key: '',
    help: '',
    rules: [
      {
        required: false,
        message: '必填項'
      }
    ]
  },
  {
    type: 'uploadFile', // 表單類型
    label: '上傳文件', // 標題文字
    icon: 'icon-upload',
    options: {
      defaultValue: '[]',
      multiple: false,
      disabled: false,
      hidden: false, // 是否隱藏，false顯示，true隱藏
      drag: false,
      downloadWay: 'a',
      dynamicFun: '',
      width: '100%',
      limit: 3,
      data: '{}',
      fileName: 'file',
      headers: {},
      action: 'http://cdn.kcz66.com/uploadFile.txt',
      placeholder: '上傳'
    },
    model: '',
    key: '',
    help: '',
    rules: [
      {
        required: false,
        message: '必填項'
      }
    ]
  },
  {
    type: 'uploadImg',
    label: '上傳圖片',
    icon: 'icon-image',
    options: {
      defaultValue: '[]',
      multiple: false,
      hidden: false, // 是否隱藏，false顯示，true隱藏
      disabled: false,
      width: '100%',
      data: '{}',
      limit: 3,
      placeholder: '上傳',
      fileName: 'image',
      headers: {},
      action: 'http://cdn.kcz66.com/upload-img.txt',
      listType: 'picture-card'
    },
    model: '',
    key: '',
    help: '',
    rules: [
      {
        required: false,
        message: '必填項'
      }
    ]
  },
  {
    type: 'treeSelect', // 表單類型
    label: '樹選擇器', // 標題文字
    icon: 'icon-tree',
    options: {
      disabled: false, // 是否禁用
      defaultValue: undefined, // 默認值
      multiple: false,
      hidden: false, // 是否隱藏，false顯示，true隱藏
      clearable: false, // 是否顯示清除按鈕
      showSearch: false, // 是否顯示搜索框，搜索選擇的項的值，而不是文字
      treeCheckable: false,
      placeholder: '請選擇',
      dynamicKey: '',
      dynamic: true,
      options: [
        {
          value: '1',
          label: '選項1',
          children: [
            {
              value: '11',
              label: '選項11'
            }
          ]
        },
        {
          value: '2',
          label: '選項2',
          children: [
            {
              value: '22',
              label: '選項22'
            }
          ]
        }
      ]
    },
    model: '',
    key: '',
    help: '',
    rules: [
      {
        required: false,
        message: '必填項'
      }
    ]
  },
  {
    type: 'cascader', // 表單類型
    label: '級聯選擇器', // 標題文字
    icon: 'icon-guanlian',
    options: {
      disabled: false, // 是否禁用
      hidden: false, // 是否隱藏，false顯示，true隱藏
      defaultValue: undefined, // 默認值
      showSearch: false, // 是否顯示搜索框，搜索選擇的項的值，而不是文字
      placeholder: '請選擇',
      clearable: false, // 是否顯示清除按鈕
      dynamicKey: '',
      dynamic: true,
      options: [
        {
          value: '1',
          label: '選項1',
          children: [
            {
              value: '11',
              label: '選項11'
            }
          ]
        },
        {
          value: '2',
          label: '選項2',
          children: [
            {
              value: '22',
              label: '選項22'
            }
          ]
        }
      ]
    },
    model: '',
    key: '',
    help: '',
    rules: [
      {
        required: false,
        message: '必填項'
      }
    ]
  },
  {
    type: 'batch',
    label: '動態表格',
    icon: 'icon-biaoge',
    list: [],
    options: {
      scrollY: 0,
      disabled: false,
      hidden: false, // 是否隱藏，false顯示，true隱藏
      showLabel: false,
      hideSequence: false,
      width: '100%'
    },
    model: '',
    key: '',
    help: ''
  },
  {
    type: 'selectInputList',
    label: '選擇輸入列',
    icon: 'icon-biaoge',
    columns: [
      {
        value: '1',
        label: '選項1',
        list: []
      },
      {
        value: '2',
        label: '選項2',
        list: []
      }
    ],
    options: {
      disabled: false,
      multiple: true, // 是否允許多選
      hidden: false, // 是否隱藏，false顯示，true隱藏
      showLabel: false,
      width: '100%'
    },
    model: '',
    key: '',
    help: ''
  },
  {
    type: 'editor',
    label: '富文本',
    icon: 'icon-LC_icon_edit_line_1',
    list: [],
    options: {
      height: 300,
      placeholder: '請輸入',
      defaultValue: '',
      chinesization: true,
      hidden: false, // 是否隱藏，false顯示，true隱藏
      disabled: false,
      showLabel: false,
      width: '100%'
    },
    model: '',
    key: '',
    help: '',
    rules: [
      {
        required: false,
        message: '必填項'
      }
    ]
  },
  {
    type: 'switch', // 表單類型
    label: '開關', // 標題文字
    icon: 'icon-kaiguan3',
    options: {
      defaultValue: false, // 默認值 Boolean 類型
      hidden: false, // 是否隱藏，false顯示，true隱藏
      disabled: false // 是否禁用
    },
    model: '',
    key: '',
    help: '',
    rules: [
      {
        required: false,
        message: '必填項'
      }
    ]
  },
  {
    type: 'button', // 表單類型
    label: '按鈕', // 標題文字
    icon: 'icon-button-remove',
    options: {
      type: 'primary',
      handle: 'submit',
      dynamicFun: '',
      hidden: false, // 是否隱藏，false顯示，true隱藏
      disabled: false // 是否禁用，false不禁用，true禁用
    },
    key: ''
  },
  {
    type: 'alert',
    label: '警告提示',
    icon: 'icon-zu',
    options: {
      type: 'success',
      description: '',
      showIcon: false,
      banner: false,
      hidden: false, // 是否隱藏，false顯示，true隱藏
      closable: false
    },
    key: ''
  },
  {
    type: 'text',
    label: '文字',
    icon: 'icon-zihao',
    options: {
      textAlign: 'left',
      hidden: false, // 是否隱藏，false顯示，true隱藏
      showRequiredMark: false,
      color: 'rgb(0, 0, 0)',
      fontFamily: 'SimHei',
      fontSize: '16pt'
    },
    key: ''
  },
  {
    type: 'html',
    label: 'HTML',
    icon: 'icon-ai-code',
    options: {
      hidden: false, // 是否隱藏，false顯示，true隱藏
      defaultValue: '<strong>HTML</strong>'
    },
    key: ''
  }
]

// 高級控件
// export const highList = [];

// import { Alert } from "ant-design-vue";

// 自定義組件
export const customComponents = {
  title: '自定義組件',
  list: [
    // {
    //   label: "測試",
    //   type: "jkjksdf",
    //   component: Alert,
    //   options: {
    //     multiple: false,
    //     disabled: false,
    //     width: "100%",
    //     data: "{}",
    //     limit: 3,
    //     placeholder: "上傳",
    //     action: "",
    //     listType: "picture-card"
    //   },
    //   model: "",
    //   key: "",
    //   rules: [
    //     {
    //       required: false,
    //       message: "必填項"
    //     }
    //   ]
    // }
  ]
}
// window.$customComponentList = customComponents.list;

// 布局控件
export const layoutList = [
  {
    type: 'divider',
    label: '分割線',
    icon: 'icon-fengexian',
    options: {
      orientation: 'left'
    },
    key: '',
    model: ''
  },
  {
    type: 'card',
    label: '卡片布局',
    icon: 'icon-qiapian',
    list: [],
    key: '',
    model: ''
  },
  {
    type: 'tabs',
    label: '標簽頁布局',
    icon: 'icon-tabs',
    options: {
      tabBarGutter: null,
      type: 'line',
      tabPosition: 'top',
      size: 'default',
      animated: true
    },
    columns: [
      {
        value: '1',
        label: '選項1',
        list: []
      },
      {
        value: '2',
        label: '選項2',
        list: []
      }
    ],
    key: '',
    model: ''
  },
  {
    type: 'grid',
    label: '柵格布局',
    icon: 'icon-zhage',
    columns: [
      {
        span: 12,
        list: []
      },
      {
        span: 12,
        list: []
      }
    ],
    options: {
      gutter: 0
    },
    key: '',
    model: ''
  },
  {
    type: 'table',
    label: '表格布局',
    icon: 'icon-biaoge',
    trs: [
      {
        tds: [
          {
            colspan: 1,
            rowspan: 1,
            list: []
          },
          {
            colspan: 1,
            rowspan: 1,
            list: []
          }
        ]
      },
      {
        tds: [
          {
            colspan: 1,
            rowspan: 1,
            list: []
          },
          {
            colspan: 1,
            rowspan: 1,
            list: []
          }
        ]
      }
    ],
    options: {
      width: '100%',
      bordered: true,
      bright: false,
      small: true,
      customStyle: ''
    },
    key: '',
    model: ''
  }
]
