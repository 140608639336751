//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['record', 'value', 'parentDisabled'],
  computed: {
    time() {
      if (!this.value) {
        return undefined
      } else {
        return moment(this.value, this.record.options.format)
      }
    }
  },
  methods: {
    handleSelectChange(val) {
      let time
      if (!val) {
        time = ''
      } else {
        time = val.format(this.record.options.format)
      }
      this.$emit('change', time)
      this.$emit('input', time)
    }
  }
}
