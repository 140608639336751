import { axios } from '@/utils/request'
/**
 * 分頁查詢
 * @param {*} data
 * @returns
 */
export const noticePage = data => axios({
  url: '/document/notice/page',
  method: 'post',
  data
})
/**
 * 獲取未讀消息數
 * @param {*} params
 * @returns
 */
export const unReadNums = () => axios({
  url: '/document/notice/unRead',
  method: 'get'
})
/**
 * 一鍵已讀
 * @param {*} data
 * @returns
 */
export const noticeFlush = () => axios({
  url: '/document/notice/flush',
  method: 'get'
})
/**
 * 點擊跳轉更新狀態為已讀
 * @param {*} id
 * @returns
 */
export const noticeUpdate = id => axios({
  url: `/document/notice/update/${id}`,
  method: 'get'
})

/**
 * 更新狀態為已讀
 * @param {*} id
 * @returns
 */
 export const noticeRefreshUpdate = id => axios({
  url: `/document/notice/refresh/${id}`,
  method: 'get'
})

/**
 * 點擊跳轉更新狀態為已讀
 * @param {*} id
 * @returns
 */
 export const noticeclearByIds = data => axios({
  url: `/document/notice/clear`,
  method: 'post',
  data
})
