//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: '表單設計器 --by kcz'
    }
  }
}
