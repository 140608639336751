//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CustomComponent',
  // eslint-disable-next-line vue/require-prop-types
  props: ['record', 'formConfig', 'disabled', 'dynamicData'],
  computed: {
    customComponent() {
      // 計算需要顯示的組件
      const customComponentList = {}
      if (window.$customComponentList) {
        // 將數組映射成json
        window.$customComponentList.forEach(item => {
          customComponentList[item.type] = item.component
        })
      }
      return customComponentList[this.record.type]
    }
  },
  methods: {
    handleChange(value, key) {
      this.$emit('change', value, key)
    }
  }
}
