//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
 * author kcz
 * date 2019-11-20
 * description 表單設計器
 */
import kHeader from './module/header'
import operatingArea from './module/operatingArea'

// import kFooter from "./module/footer";
import kFormComponentPanel from './module/formComponentPanel'
import kJsonModal from './module/jsonModal'
import kCodeModal from './module/codeModal'
import collapseItem from './module/collapseItem'
import importJsonModal from './module/importJsonModal'
import previewModal from '../KFormPreview/index.vue'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'

import { Revoke } from '../core/revoke'
import {
  basicsList,
  layoutList,
  customComponents
} from './config/formItemsConfig'
import formItemProperties from './module/formItemProperties'
import formProperties from './module/formProperties'
export default {
  name: 'KFormDesign',
  props: {
    title: {
      type: String,
      default: '表單設計器 --by kcz'
    },
    showHead: {
      type: Boolean,
      default: true
    },
    hideResetHint: {
      type: Boolean,
      default: false
    },
    toolbarsTop: {
      type: Boolean,
      default: false
    },
    toolbars: {
      type: Array,
      default: () => [
        'save',
        'preview',
        'importJson',
        'exportJson',
        'exportCode',
        'reset',
        'close',
        'undo',
        'redo'
      ]
    },
    showToolbarsText: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Array,
      default: () => [
        'input',
        'textarea',
        'number',
        'select',
        'checkbox',
        'radio',
        'date',
        'time',
        'rate',
        'slider',
        'uploadFile',
        'uploadImg',
        'cascader',
        'treeSelect',
        'batch',
        'selectInputList',
        'editor',
        'switch',
        'button',
        'alert',
        'text',
        'html',
        'divider',
        'card',
        'tabs',
        'grid',
        'table'
      ]
    },
    hideModel: {
      // 隱藏數據字段
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      locale: zhCN,
      customComponents,
      activeKey: 1,
      updateTime: 0,
      updateRecordTime: 0,
      startType: '',
      revoke: null,
      recordList: [],
      redoList: [],
      noModel: [
        'button',
        'divider',
        'card',
        'grid',
        'tabs',
        'table',
        'alert',
        'text',
        'html'
      ],
      data: {
        list: [],
        config: {
          layout: 'horizontal',
          labelCol: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4, xxl: 4 },
          labelWidth: 100,
          labelLayout: 'flex',
          wrapperCol: { xs: 18, sm: 18, md: 18, lg: 18, xl: 18, xxl: 18 },
          hideRequiredMark: false,
          customStyle: ''
        }
      },
      previewOptions: {
        width: 850
      },
      selectItem: {
        key: ''
      }
    }
  },
  components: {
    kHeader,
    // kFooter,
    operatingArea,
    collapseItem,
    kJsonModal,
    kCodeModal,
    importJsonModal,
    previewModal,
    kFormComponentPanel,
    formItemProperties,
    formProperties
  },
  watch: {
    data: {
      handler(e) {
        this.$nextTick(() => {
          this.revoke.push(e)
        })
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    basicsArray() {
      // 計算需要顯示的基礎字段
      return basicsList.filter(item => this.fields.includes(item.type))
    },
    layoutArray() {
      // 計算需要顯示的布局字段
      return layoutList.filter(item => this.fields.includes(item.type))
    },
    collapseDefaultActiveKey() {
      // 計算當前展開的控件列表
      const defaultActiveKey = window.localStorage.getItem(
        'collapseDefaultActiveKey'
      )
      if (defaultActiveKey) {
        return defaultActiveKey.split(',')
      } else {
        return ['1']
      }
    }
  },
  methods: {
    generateKey(list, index) {
      // 生成key值
      const key = list[index].type + '_' + new Date().getTime()
      this.$set(list, index, {
        ...list[index],
        key,
        model: key
      })
      if (this.noModel.includes(list[index].type)) {
        // 刪除不需要的model屬性
        delete list[index].model
      }
    },
    handleListPush(item) {
      // 雙擊控件按鈕push到list
      // 生成key值
      if (!this.selectItem.key) {
        // 在沒有選擇表單時，將數據push到this.data.list
        const key = item.type + '_' + new Date().getTime()
        item = {
          ...item,
          key,
          model: key
        }
        if (this.noModel.includes(item.type)) {
          // 刪除不需要的model屬性
          delete item.model
        }
        const itemString = JSON.stringify(item)
        const record = JSON.parse(itemString)
        // 刪除icon及compoent屬性
        delete record.icon
        delete record.component
        this.data.list.push(record)
        this.handleSetSelectItem(record)
        return false
      }
      this.$refs.KFCP.handleCopy(false, item)
    },
    handleOpenJsonModal() {
      // 打開json預覽模態框
      this.$refs.jsonModal.jsonData = this.data
      this.$refs.jsonModal.visible = true
    },
    handleOpenCodeModal() {
      // 打開代碼預覽模態框
      this.$refs.codeModal.jsonData = this.data
      this.$refs.codeModal.visible = true
    },
    handleOpenImportJsonModal() {
      // 打開json預覽模態框
      this.$refs.importJsonModal.jsonData = this.data
      this.$refs.importJsonModal.handleSetSelectItem = this.handleSetSelectItem
      this.$refs.importJsonModal.visible = true
    },
    handlePreview() {
      // 打開預覽模態框
      this.$refs.previewModal.jsonData = this.data
      this.$refs.previewModal.previewWidth = this.previewOptions.width
      this.$refs.previewModal.visible = true
    },
    handleReset() {
      // 清空
      if (this.hideResetHint) {
        // 不顯示提示直接清空
        this.resetData()
        return
      }

      this.$confirm({
        title: '警告',
        content: '是否確認清空內容?',
        okText: '是',
        okType: 'danger',
        cancelText: '否',
        onOk: () => {
          this.resetData()
        }
      })
    },
    resetData() {
      this.data = {
        list: [],
        config: {
          layout: 'horizontal',
          labelCol: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4, xxl: 4 },
          labelWidth: 100,
          labelLayout: 'flex',
          wrapperCol: { xs: 18, sm: 18, md: 18, lg: 18, xl: 18, xxl: 18 },
          hideRequiredMark: false,
          customStyle: ''
        }
      }
      this.handleSetSelectItem({ key: '' })
      this.$message.success('已清空')
    },
    handleSetSelectItem(record) {
      // 操作間隔不能低於100毫秒
      const newTime = new Date().getTime()
      if (newTime - this.updateTime < 100) {
        return false
      }

      this.updateTime = newTime

      // 設置selectItem的值
      this.selectItem = record

      // 判斷是否選中控件，如果選中則彈出屬性面板，否則關閉屬性面板
      if (record.key) {
        this.startType = record.type
        this.changeTab(2)
      } else {
        this.changeTab(1)
      }
    },
    /**
     * @description: 切換屬性設置面板
     * @param {*}
     * @return {*}
     */

    changeTab(e) {
      this.activeKey = e
    },
    /**
     * @Author: kcz
     * @description: 遍歷json結構，獲取所有字段
     * @param {*}
     * @return {*} Array
     */
    getFieldSchema() {
      const fields = []
      const traverse = array => {
        array.forEach(element => {
          if (element.type === 'grid' || element.type === 'tabs') {
            // 柵格布局
            element.columns.forEach(item => {
              traverse(item.list)
            })
          } else if (element.type === 'card') {
            // 卡片布局
            traverse(element.list)
          } else if (element.type === 'batch') {
            // 動態表格內復制
            traverse(element.list)
          } else if (element.type === 'table') {
            // 表格布局
            element.trs.forEach(item => {
              item.tds.forEach(val => {
                traverse(val.list)
              })
            })
          } else {
            if (element.model) {
              fields.push(element)
            }
          }
        })
      }
      traverse(this.data.list)
      return fields
    },
    handleSetData(data) {
      // 用於父組件賦值
      try {
        if (typeof data !== 'object') {
          return false
        } else {
          this.data = data
          // 導入json數據後，需要清除已選擇key
          this.handleSetSelectItem({ key: '' })
        }
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },
    collapseChange(val) {
      // 點擊collapse時，保存當前collapse狀態
      window.localStorage.setItem('collapseDefaultActiveKey', val)
    },
    handleStart(type) {
      this.startType = type
    },

    /**
     * @description: 撤銷
     * @param {*}
     * @return {*}
     */
    handleUndo() {
      const record = this.revoke.undo()
      if (!record) {
        return false
      }
      this.data = record

      this.handleSetSelectItem({ key: '' })
    },

    /**
     * @description: 重做
     * @param {*}
     * @return {*}
     */
    handleRedo() {
      const record = this.revoke.redo()
      if (!record) {
        return false
      }
      this.data = record
    },

    handleSave() {
      // 保存函數
      this.$emit('save', JSON.stringify(this.data))
    },
    getValue() {
      // 獲取數據
      return this.data
    },
    handleClose() {
      this.$emit('close')
    }
  },
  created() {
    this.revoke = new Revoke()
    this.recordList = this.revoke.recordList
    this.redoList = this.revoke.redoList
  }
}
