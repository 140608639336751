//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      visible: false,
      value: '1111',
      key: '',
    }
  },
  methods: {
    open(key) {
      this.key = key
      this.visible = true
    },
    handleOk() {
      this.$$emit('getvalue', {
        [this.key]: this.value,
      })
    },
  },
}
