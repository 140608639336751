//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 剪切板組件
import Clipboard from 'clipboard'
import { codemirror } from 'vue-codemirror-lite'
export default {
  name: 'PreviewCode',
  props: {
    fileFormat: {
      type: String,
      default: 'json'
    },
    editorJson: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false
    }
  },

  components: {
    codemirror
  },
  methods: {
    exportData(data, fileName = `demo.${this.fileFormat}`) {
      let content = 'data:text/csv;charset=utf-8,'
      content += data
      var encodedUri = encodeURI(content)
      var actions = document.createElement('a')
      actions.setAttribute('href', encodedUri)
      actions.setAttribute('download', fileName)
      actions.click()
    },
    handleExportJson() {
      // 導出JSON
      this.exportData(this.editorJson)
    },
    handleCopyJson() {
      // 復制數據
      const clipboard = new Clipboard('.copy-btn')
      clipboard.on('success', () => {
        this.$message.success('復制成功')
      })
      clipboard.on('error', () => {
        this.$message.error('復制失敗')
      })
      setTimeout(() => {
        // 銷毀實例
        clipboard.destroy()
      }, 122)
    }
  }
}
