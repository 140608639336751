//
//
//
//
//
//
//
//
//
//
//
//
//

import { NavBar } from 'vant'
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      title: '公文流轉系統',
      isArrow: true,
    }
  },
  created() {
    this.isArrow = this.$route.name != 'mobileHome'
    // console.log(this.$route.name, '++++++++++++', this.$router)
  },
  methods: {
    goback() {
      console.log(11111111)
    },
  },
}
