//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Pagination } from 'ant-design-vue'
import { dialogMsgConfig } from './config'
import msgListComponent from './msgListComponent.vue'
export default {
  props: {
    noticeType: {
      type: String,
      default: '',
    },
    isUnRead: {
      type: Boolean,
      default: false,
    },
    // 是否展示清除按鈕
    isClearMsg: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isUnRead: {
      handler() {
        this.pageNo = 1
      },
    },
  },
  computed: {
    config() {
      const noticeType = this.noticeType
      return dialogMsgConfig.find((j) => j.key === noticeType)
    },
  },
  components: {
    msgListComponent,
    [Pagination.name]: Pagination,
  },
  data() {
    return {
      current: 1,
      article: 10,
      indeterminate: false,
      checkAll: false,
      checkedMsgList: [],
    }
  },
  methods: {
    pageConfig(evt) {
      if (evt.totalRows === 0 || evt.totalRows) this.article = evt.totalRows
      this.$emit('pageConfig', evt)
    },
    close() {
      this.$emit('close')
    },
    handleChange(evt) {
      this.$refs.msgListComponent.changePage({ pageNo: evt })
    },
    onShowSizeChange(_, pageSize) {
      this.$nextTick(() => {
        this.current = 1
        this.$refs.msgListComponent.changePage({ pageNo: 1, pageSize })
      })
    },
    // 清除選擇的信息
    clearMsgInfoByIds() {
      if (this.checkedMsgList.length == 0) return
      let params = {
        idList: this.checkedMsgList,
      }
      this.$store.dispatch('msg/noticeclearByIds', params).then((res) => {
        if (res.code === 200) {
          this.$message.success('清除成功~~')
          this.$refs.msgListComponent.query()
        }
      })
    },
    // 全選
    selectAll(e) {
      this.$refs.msgListComponent.selectAll(e)
    },
    // 修改全選狀態
    changeMsgselectStatus({ checkAll, indeterminate, checkedMsgList }) {
      this.checkAll = checkAll
      this.indeterminate = indeterminate
      this.checkedMsgList = checkedMsgList
    },
  },
}
