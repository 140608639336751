import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
//
import { Dialog } from '@/components'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './components/global.less'
import bootstrap from './core/bootstrap'
import './core/lazy_use'
import { serverUrl } from './mixins/config'
import './permission'
import { sysApplication } from './utils/applocation'
import './utils/customIcon'
import './utils/filter'
import LoadingConstructor from './utils/loading'
import { hasBtnPermission } from './utils/permissions'

import { VueAxios } from './utils/request'
import { isIOS, isMobile } from './utils/util'
//
// import preview from 'vue-photo-preview'
// import 'vue-photo-preview/dist/skin.css'
// 表單設計器 str
import VConsole from 'vconsole'
import KFormDesign from './components/k-form-design/packages'
import './components/k-form-design/styles/form-design.less'
//
import FastClick from 'fastclick'

// 字體文件 王念
import zh_tran from './language/config/lang.js'
Vue.prototype.$zh_tran = zh_tran


//----------------
if(isIOS()){
  console.log('ios環境')
  FastClick.attach(document.body)
}
// vant
import 'vant/lib/index.css'
import './config/vant.useConf'
if(process.env.NODE_ENV !=='development'&&isMobile()){
  // const vConsole = new VConsole();
}
if(!isMobile()){
  require( './utils/rem.js')
}
if(!isMobile()){
  require( './utils/rem.js')
}
//wn修改引入語言包
// import i18n from "./language"
// 修改語言
//  Vue.use(i18n)
// vant
Vue.use(KFormDesign)
// 表單設計器 end
LoadingConstructor()
Vue.use(VueAxios)
Vue.use(Dialog)
// Vue.use(preview)
Vue.prototype.hasPerm = hasBtnPermission
Vue.prototype.applocation = sysApplication
Vue.prototype.serverUrl = serverUrl
Vue.config.productionTip = false

new Vue({
  router,
  store,
  created: bootstrap,
  render: h => h(App)
}).$mount('#app')
