//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import types from './type'

export default {
  name: 'Exception',
  props: {
    type: {
      type: String,
      default: '404'
    }
  },
  data () {
    return {
      config: types
    }
  },
  methods: {
    handleToHome () {
      this.$router.push({ name: 'Console' })
    }
  }
}
