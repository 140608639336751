// rem等比適配配置文件
// 基準大小
const baseSize = 16;
// 設置 rem 函數
function setRem() {
  // 當前頁面屏幕分辨率相對於 1280寬的縮放比例，可根據自己需要修改
  const scale = document.documentElement.clientWidth / 1920;
  // 設置頁面根節點字體大小（“Math.min(scale, 3)” 指最高放大比例為3，可根據實際業務需求調整）
  document.documentElement.style.fontSize = `${baseSize * Math.min(scale, 3)}px`;
}
// 初始化
setRem();
// 改變窗口大小時重新設置 rem
window.onresize = () => {
  setRem();
};
