//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Bar',
    props: {
        title: {
            type: String,
            default: '',
        },
        data: {
            type: Array,
            default: () => {
                return []
            },
        },
        scale: {
            type: Array,
            default: () => {
                return [
                    {
                        dataKey: 'x',
                        min: 2,
                    },
                    {
                        dataKey: 'y',
                        title: '時間',
                        min: 1,
                        max: 22,
                    },
                ]
            },
        },
        tooltip: {
            type: Array,
            default: () => {
                return [
                    'x*y',
                    (x, y) => ({
                        name: x,
                        value: y,
                    }),
                ]
            },
        },
    },
    data() {
        return {}
    },
}
