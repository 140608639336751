//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
 * author kcz
 * date 2019-12-31
 * description 上傳圖片組件
 */
export default {
  name: 'KUploadImg',
  // eslint-disable-next-line vue/require-prop-types
  props: ['record', 'value', 'config', 'parentDisabled'],
  data() {
    return {
      fileList: [],
      previewVisible: false,
      previewImageUrl: ''
    }
  },
  watch: {
    value: {
      // value 需要深度監聽及默認先執行handler函數
      handler(val) {
        if (val) {
          this.setFileList()
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    optionsData() {
      try {
        return JSON.parse(this.record.options.data)
      } catch (err) {
        console.error(err)
        return {}
      }
    }
  },
  methods: {
    setFileList() {
      // 當傳入value改變時，fileList也要改變
      // 如果傳入的值為字符串，則轉成json
      if (typeof this.value === 'string') {
        this.fileList = JSON.parse(this.value)
        // 將轉好的json覆蓋組件默認值的字符串
        this.handleSelectChange()
      } else {
        this.fileList = this.value
      }
    },
    handleSelectChange() {
      setTimeout(() => {
        const arr = this.fileList.map(item => {
          if (typeof item.response !== 'undefined') {
            const res = item.response
            return {
              type: 'img',
              name: item.name,
              status: item.status,
              uid: item.uid,
              url: res.data.url || ''
            }
          } else {
            return {
              type: 'img',
              name: item.name,
              status: item.status,
              uid: item.uid,
              url: item.url || ''
            }
          }
        })

        this.$emit('change', arr)
        this.$emit('input', arr)
      }, 10)
    },
    handlePreview(file) {
      // 預覽圖片
      this.previewImageUrl = file.url || file.thumbUrl
      this.previewVisible = true
    },
    handleCancel() {
      // 取消操作
      this.previewVisible = false
    },
    remove() {
      this.handleSelectChange()
    },
    beforeUpload(e, files) {
      if (files.length + this.fileList.length > this.record.options.limit) {
        this.$message.warning(`最大上傳數量為${this.record.options.limit}`)
        files.splice(this.record.options.limit - this.fileList.length)
      }
    },
    handleChange(info) {
      // 上傳數據改變時
      this.fileList = info.fileList
      if (info.file.status === 'done') {
        const res = info.file.response
        if (res.code === 0) {
          this.handleSelectChange()
        } else {
          this.fileList.pop()
          this.$message.error(`圖片上傳失敗`)
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`圖片上傳失敗`)
      }
    }
  }
}
