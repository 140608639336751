//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
 * author kcz
 * date 2019-11-20
 */
import jsonModel from '../KFormDesign/module/jsonModal'
export default {
  name: 'KFormPreview',
  data() {
    return {
      visible: false,
      previewWidth: 850,
      jsonData: {}
    }
  },
  components: {
    jsonModel
  },
  methods: {
    handleSubmit(p) {
      p.then(res => {
        this.$refs.jsonModel.jsonData = res
        this.$refs.jsonModel.visible = true
      }).catch(err => {
        console.error(err, '獲取數據失敗')
      })
    },
    handleGetData() {
      this.$refs.KFormBuild.getData()
        .then(res => {
          this.$refs.jsonModel.jsonData = res
          this.$refs.jsonModel.visible = true
        })
        .catch(err => {
          console.log(err, '獲取數據失敗')
        })
    },
    handleCancel() {
      this.visible = false
    }
  }
}
