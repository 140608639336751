//
//
//
//
//
//
//
//
//
//

export default {
  name: 'GlobalFooter',
  data () {
    return {}
  }
}
