//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
 * author kcz
 * date 2019-11-20
 */
import KFormItem from '../KFormItem/index'
export default {
  name: 'BuildBlocks',
  props: {
    record: {
      type: Object,
      required: true
    },
    formConfig: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      default: () => ({})
    },
    dynamicData: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validatorError: {
      type: [Object, null],
      default: () => ({})
    }
  },
  components: {
    KFormItem
  },
  data() {
    return {
      activeKey: 0
    }
  },
  methods: {
    validationSubform() {
      // 驗證動態表格
      const nestedComponents = this.$refs.nestedComponents
      if (
        typeof nestedComponents === 'object' &&
        nestedComponents instanceof Array
      ) {
        for (let i = 0; nestedComponents.length > i; i++) {
          if (!nestedComponents[i].validationSubform()) {
            return false
          }
        }
        return true
      } else if (typeof nestedComponents !== 'undefined') {
        return nestedComponents.validationSubform()
      } else {
        return true
      }
    },
    handleChange(value, key) {
      this.$emit('change', value, key)
    }
  },
  watch: {
    /**
     * @author: lizhichao<meteoroc@outlook.com>
     * @description: 監視validatorError對象，當檢測到Tabs中有表單校驗無法通過時，切換到最近校驗失敗的tab頁。
     */
    validatorError: {
      deep: true,
      handler: function(n) {
        const errorItems = Object.keys(n)
        if (errorItems.length) {
          if (!this.record.columns) return false
          for (let i = 0; i < this.record.columns.length; i++) {
            const err = this.record.columns[i].list.filter(item =>
              errorItems.includes(item.model)
            )
            if (err.length) {
              this.activeKey = i
              break
            }
          }
        }
      }
    }
  }
}
