import {
  MobielLayout
} from '@/layouts'
export const mobileRouter = [{
  path: '/mobile',
  name: 'mobile',
  component: MobielLayout,
  children: [{
    path: 'home',
    name: 'mobileHome',
    meta: {
      title: '首頁'
    },
    component: () => import(/* webpackChunkName: "mobileHome" */ '@/views/mobile/home')
  }, {
    path: 'send',
    name: 'mobileBillSend',
    component: () => import(/* webpackChunkName: "mobileBillSend" */ '@/views/mobile/mobileBillSend')
  }, {
    path: '/mobileBillRegister',
    name: 'mobileBillRegister',
    meta: {
      title: '收文登記'
    },
    component: () => import(/* webpackChunkName: "mobileBillRegister" */ '@/views/mobile/billRegister')
  }, {
    path: '/MobileMy',
    name: 'MobileMy',
    meta: {
      title: '個人中心'
    },
    component: () => import(/* webpackChunkName: "mobileApproval" */ '@/views/mobile/my')
  }]
}, {
  path: '/mobileBillEdit',
  name: 'mobileBillEdit',
  meta: {
    title: '收文修改'
  },
  component: () => import(/* webpackChunkName: "mobileBillEdit" */ '@/views/mobile/billRegister/editBill')
}, {
  path: '/mobileEditBill',
  name: 'mobileEditBill',
  meta: {
    title: '收文修改'
  },
  component: () => import(/* webpackChunkName: "mobileEditBill" */ '@/views/mobile/mobileBillSend/mobileEditBill')
}, {
  path: '/mobileDocumentApproval',
  name: 'mobileDocumentApproval',
  meta: {
    title: '公文審批'
  },
  component: () => import(/* webpackChunkName: "mobileDocumentApproval" */ '@/views/mobile/mobileDocumentApproval')
}, {
  path: '/mobileBillDetail',
  name: 'mobileBillDetail',
  meta: {
    title: '公文詳情'
  },
  component: () => import(/* webpackChunkName: "mobileBillDetail" */ '@/views/mobile/mobileBillSend/mobileBillDetail')
}, {
  path: '/mobileCreateApproval',
  name: 'mobileCreateApproval',
  meta: {
    title: '發起審批'
  },
  component: () => import(/* webpackChunkName: "mobileCreateApproval" */ '@/views/mobile/mobileDocumentApproval/mobileCreateApproval')
}, {
  path: '/mobileCountersign',
  name: 'mobileCountersign',
  meta: {
    title: '添加節點'
  },
  component: () => import(/* webpackChunkName: "mobileCountersign" */ '@/views/mobile/billRegister/countersign')
}, {
  path: '/mobileApproval',
  name: 'mobileApproval',
  meta: {
    title: '審批公文'
  },
  component: () => import(/* webpackChunkName: "mobileApproval" */ '@/views/mobile/mobileDocumentApproval/mobileApproval')
}, {
  path: '/showMobileApproval',
  name: 'showMobileApproval',
  meta: {
    title: '接收公文'
  },
  component: () => import(/* webpackChunkName: "mobileApproval" */ '@/views/mobile/mobileDocumentApproval/showMobileApproval')
}, {
  path: '/mobileLabelManage',
  name: 'mobileLabelManage',
  meta: {
    title: '標簽管理'
  },
  component: () => import(/* webpackChunkName: "mobileLabelManage" */ '@/views/mobile/mobileLabelManage')
}, {
  path: '/selectLabel',
  name: 'selectLabel',
  meta: {
    title: '標簽人員選擇'
  },
  component: () => import(/* webpackChunkName: "selectLabel" */ '@/views/mobile/mobileLabelManage/selectLabel')
}, {
  path: '/addLabelName',
  name: 'addLabelName',
  meta: {
    title: '添加標簽'
  },
  component: () => import(/* webpackChunkName: "addLabelName" */ '@/views/mobile/mobileLabelManage/addLabelName')
}, {
  path: '/addLabelPerson',
  name: 'addLabelPerson',
  meta: {
    title: '添加成員'
  },
  component: () => import(/* webpackChunkName: "addLabelPerson" */ '@/views/mobile/mobileLabelManage/addLabelPerson')
}, {
  path: '/delectLabel',
  name: 'delectLabel',
  meta: {
    title: '刪除標簽'
  },
  component: () => import(/* webpackChunkName: "delectLabel" */ '@/views/mobile/mobileLabelManage/delectLabel')
}, {
  path: '/mobileShowForm',
  name: 'mobileShowForm',
  meta: {
    title: '查看表單'
  },
  component: () => import(/* webpackChunkName: "mobileShowForm" */ '@/views/mobile/billRegister/showForm')
}]

const _getNameArray = (mobileRouter, result) => {
  mobileRouter.forEach(j => {
    result.push(j.name)
    if (j.children && j.children.length > 0) {
      _getNameArray(j.children, result)
    }
  })
}

export const nameArray = () => {
  const arr = []
  _getNameArray(mobileRouter, arr)
  return [...new Set(arr)]
}
