//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { VueCropper } from 'vue-cropper'
export default {
  components: { VueCropper },
  props: {
    circle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 配置信息
      option: {
        img: '',
        size: 1,
        full: false,
        outputType: 'png',
        canMove: true,
        fixedBox: this._props.circle,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 200,
        autoCropHeight: 200,
        centerBox: false,
        high: false,
        cropData: {},
        enlarge: 1,
        mode: 'contain',
        maxImgSize: 3000,
        limitMinSize: [100, 120],
      },
      visible: false,
      previewUrl: '',
      fileName: '', // 上傳文件名稱
      fileType: '', // 上傳圖片類型
    }
  },
  created() {},
  methods: {
    getBase64(img, callback) {
      const reader = new FileReader()
      reader.addEventListener('load', () => callback(reader.result))
      reader.readAsDataURL(img)
    },
    cropOk() {
      this.$refs.cropper.getCropBlob((blobData) => {
        this.$emit('successful', { fileName: this.fileName, blobData: blobData, fileType: this.fileType })
        this.handleClear()
      })
    },
    // 情況當前值
    handleClear() {
      this.visible = false
      this.option.img = this.previewUrl = this.fileName = ''
    },
    // 上傳文件
    uploadChange({ file }) {
      const { getBase64 } = this
      getBase64(file.originFileObj, (imageUrl) => {
        this.fileName = file.name
        this.fileType = file.type
        this.option.img = imageUrl
        console.log('file++++++++', file)
      })
    },
    show({ avatar }) {
      const { option } = this
      option.img = avatar
      this.visible = true
    },
    getCropdata() {
      this.$refs.cropper.getCropData((data) => {
        // this.previewUrl = data
        this.$set(this, 'previewUrl', data)
      })
    },
  },
}
