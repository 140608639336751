//
//
//
//
//
//
//
//
//
//
//

const classes = [
  'antd-pro-components-standard-form-row-index-standardFormRowBlock',
  'antd-pro-components-standard-form-row-index-standardFormRowGrid',
  'antd-pro-components-standard-form-row-index-standardFormRowLast'
]
export default {
  name: 'StandardFormRow',
  props: {
    prefixCls: {
      type: String,
      default: 'antd-pro-components-standard-form-row-index-standardFormRow'
    },
    title: {
      type: String,
      default: undefined
    },
    last: {
      type: Boolean
    },
    block: {
      type: Boolean
    },
    grid: {
      type: Boolean
    }
  },
  computed: {
    lastCls () {
      return this.last ? classes[2] : null
    },
    blockCls () {
      return this.block ? classes[0] : null
    },
    gridCls () {
      return this.grid ? classes[1] : null
    }
  }
}
