//
//
//
//

export default {
  props: ['type', 'openid'],
  mounted() {
    WWOpenData.on('error', (err) => {
      console.log('err++++++++', err)
    })
    WWOpenData.bind(this.$el)
  },
}
