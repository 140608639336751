import Vue from 'vue';
// 引入寫好的icon組件
import icon from '@/components/IconSelector/icon.vue';
// 註冊到全局組件
Vue.component('svgIcon', icon)

const requireContext = require.context('../icons/svg', false, /\.svg$/)

// 函數 全部導入
const importAll = r => r.keys().map(r)
importAll(requireContext)