//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['record', 'value', 'parentDisabled'],
  data() {
    return {
      // date: undefined
    }
  },
  computed: {
    date() {
      if (
        !this.value ||
        (this.record.options.range && this.value.length === 0)
      ) {
        return undefined
      } else if (this.record.options.range) {
        return this.value.map(item => moment(item, this.record.options.format))
      } else {
        return moment(this.value, this.record.options.format)
      }
    }
  },
  methods: {
    handleSelectChange(val) {
      let date
      if (!val || (this.record.options.range && val.length === 0)) {
        date = ''
      } else if (this.record.options.range) {
        date = val.map(item => item.format(this.record.options.format))
      } else {
        date = val.format(this.record.options.format)
      }
      this.$emit('change', date)
      this.$emit('input', date)
    }
  }
}
