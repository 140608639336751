//
//
//
//
//
//
//
//
//
//
//
//
//

import infiniteScroll from 'vue-infinite-scroll'
export default {
  directives: { infiniteScroll },
  props: {
    isFinish: {
      type: Boolean,
      default: false
    },
    msgListLength: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: String,
      default: '380px'
    }
  },
  data() {
    return {
      busy: false
    }
  },
  methods: {
    handleInfiniteOnLoad() {
      console.log('handleInfiniteOnLoad')
      this.$emit('load')
    }
  }
}
