//
//
//
//
//
//
//
//

import { AppDeviceEnquire } from '@/utils/mixin'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'

export default {
    mixins: [AppDeviceEnquire],
    data() {
        return {
            locale: zhCN,
        }
    },
    watch: {
        // $route: 'getPath'
        $route(to) {
        console.log('to :>> ', to);
        this.$nextTick(() => {
            this.$zh_tran(localStorage.getItem('lang'))
            this.getPath()
        })
        }
    },
    mounted() {
         this.$nextTick(() => {
          this.$zh_tran(localStorage.getItem('lang'))
        })
        localStorage.setItem('lang', 't')
        this.$zh_tran(localStorage.getItem('lang'))
        //window.addEventListener("beforeunload",(e)=> this.beforeunloadHandler(e))
        //window.addEventListener("unload",(e)=> this.beforeunloadHandler(e))
    },
    methods: {
        /**  關閉網站 */
        beforeunloadHandler() {
            alert('sssss')
            if (e) {
                e = e || window.event
                console.log(e)
                if (e) {
                    e.returnValue = '關閉提示'
                }
                return '關閉提示'
            }
        },
    },
}
