//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { quillEditor } from 'vue-quill-editor' // 調用編輯器
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  name: 'Editor',
  props: ['value', 'record', 'parentDisabled'],
  components: { quillEditor },
  data() {
    return {
      editorOption: {
        placeholder: this.record.options.placeholder
      }
    }
  },
  methods: {
    onEditorBlur() {}, // 失去焦點事件
    onEditorFocus() {}, // 獲得焦點事件
    onEditorChange(e) {
      this.$emit('change', e.html)
    }
  }
}
