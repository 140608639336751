//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import KFormModelItem from './module/KFormModelItem'
export default {
  name: 'KBatch',
  props: ['record', 'value', 'dynamicData', 'config', 'parentDisabled'],

  components: {
    KFormModelItem
  },
  watch: {
    value: {
      // value 需要深度監聽及默認先執行handler函數
      handler(val) {
        this.dynamicValidateForm.domains = val || []
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      dynamicValidateForm: {
        domains: []
      }
    }
  },
  computed: {
    listLength() {
      return this.record.list.filter(item => !item.options.hidden).length
    },
    columns() {
      const columns = []
      if (!this.record.options.hideSequence) {
        columns.push({
          title: '序號',
          dataIndex: 'sequence_index_number',
          width: '60px',
          align: 'center',
          customRender: (text, record, index) => {
            return index + 1
          }
        })
      }

      columns.push(
        ...this.record.list
          .filter(item => !item.options.hidden)
          .map((item, index) => {
            return {
              title: item.label,
              dataIndex: item.key,
              width: index === this.record.list.length - 1 ? '' : '190px',
              scopedSlots: { customRender: item.key }
            }
          })
      )

      columns.push({
        title: '操作',
        dataIndex: 'dynamic-opr-button',
        fixed: 'right',
        width: '80px',
        align: 'center',
        scopedSlots: { customRender: 'dynamic-opr-button' }
      })

      return columns
    },
    disabled() {
      return this.record.options.disabled || this.parentDisabled
    }
  },
  methods: {
    validationSubform() {
      let verification
      this.$refs.dynamicValidateForm.validate(valid => {
        verification = valid
      })
      return verification
    },
    resetForm() {
      this.$refs.dynamicValidateForm.resetFields()
    },
    removeDomain(item) {
      const index = this.dynamicValidateForm.domains.indexOf(item)
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1)
      }
    },
    copyDomain(record) {
      const data = {}
      this.record.list.forEach(item => {
        data[item.model] = record[item.model]
      })
      this.dynamicValidateForm.domains.push({
        ...data,
        key: Date.now()
      })
      this.handleInput()
    },
    addDomain() {
      const data = {}
      this.record.list.forEach(item => {
        data[item.model] = item.options.defaultValue
      })

      this.dynamicValidateForm.domains.push({
        ...data,
        key: Date.now()
      })
      this.handleInput()
    },
    handleInput() {
      this.$emit('change', this.dynamicValidateForm.domains)
    }
  }
}
