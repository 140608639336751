import store from '@/store'

/**
 * 緩存中的已選中應用
 *
 * @author yubaoshan
 * @date 2020/06/27 02:34
 */
export function sysApplication () {
  return store.getters.applocation
}
