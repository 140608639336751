//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import contactCustomerService from '@/views/userLoginReg/components/contactCustomerService.vue'
import listContainer from './list'
export default {
    components: {
        contactCustomerService,
        listContainer
    },
    data() {
        return {
            visible: false,
            title: '幫助',
            pageNo: 0,
            pageSize: 20,
            isFinish: false,
            pageList: []
        }
    },
    methods: {
        versionPage(data) {
            return this.$store.dispatch('msg/versionPage', data)
        },
        showDrawer() {
            this.handleVisible()
        },
        onClose() {
            this.handleVisible()
        },
        handleVisible() {
            this.visible = !this.visible
        },
        load() {
            if (this.isFinish) return
            this.pageNo++
            this.versionPage({
                pageNo: this.pageNo,
                pageSize: this.pageSize
            })
            .then(res => {
                if (res.code === 200) {
                    console.log(res.data)
                    const { rows } = res.data
                    this.pageList = [...this.pageList, ...rows]
                    this.isFinish = !rows || rows.length < this.pageSize
                }
            })
        }
    }
}
