//
//
//
//
//
//
//
//
//
//
//

import listContainer from './list'
import msgListComponent from './msgListComponent.vue'
import { pupDialogConfig } from './config'
export default {
  components: {
    listContainer,
    msgListComponent
  },
  props: {
    noticeType: {
      type: String,
      default: ''
    },
    isUnRead: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isUnRead: {
      handler() {
        this.pageNo = 1
      }
    }
  },
  computed: {
    config() {
      const noticeType = this.noticeType
      return pupDialogConfig.find(j => j.key === noticeType)
    },
    isFinish() {
      return this.pageNo >= this.totalPage
    }
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      totalPage: 0,
      isLoading: false,
      msgListLength: 0
    }
  },
  methods: {
    pageConfig(evt) {
      if (evt.totalPage === 0 || evt.totalPage) this.totalPage = evt.totalPage
      this.isLoading = evt.isLoading
      this.msgListLength = evt.msgListLength
    },
    refresh() {
      this.$refs.msgListComponentPup && this.$refs.msgListComponentPup.refresh()
    },
    close() {
      this.$emit('close')
    }
  }
}
