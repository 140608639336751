//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import draggable from 'vuedraggable'
import layoutItem from './layoutItem'
import 'codemirror/mode/javascript/javascript'
export default {
  name: 'KCenter',
  data() {
    return {
      form: this.$form.createForm(this),
      insertAllowedType: [
        'input',
        'textarea',
        'number',
        'select',
        'checkbox',
        'radio',
        'date',
        'time',
        'rate',
        'slider',
        'uploadFile',
        'uploadImg',
        'cascader',
        'treeSelect',
        'switch',
        'text',
        'html'
      ],
      rightMenuSelectValue: {},
      showRightMenu: false,
      menuTop: 0,
      menuLeft: 0,
      trIndex: 0,
      tdIndex: 0
    }
  },
  props: {
    noModel: {
      type: Array,
      required: true
    },
    startType: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    selectItem: {
      type: Object,
      default: () => {}
    },
    hideModel: {
      type: Boolean,
      default: false
    }
  },
  components: {
    draggable,
    layoutItem
  },
  methods: {
    deepClone(evt) {
      const newIndex = evt.newIndex
      // json深拷貝一次
      const listString = JSON.stringify(this.data.list)
      this.data.list = JSON.parse(listString)
      // 刪除icon及compoent屬性
      delete this.data.list[newIndex].icon
      delete this.data.list[newIndex].component
      this.$emit('handleSetSelectItem', this.data.list[newIndex])
    },
    handleColAdd(evt, columns, isCopy = false) {
      // 重置或者生成key值
      const newIndex = evt.newIndex
      const key = columns[newIndex].type + '_' + new Date().getTime()
      if (columns[newIndex].key === '' || isCopy) {
        this.$set(columns, newIndex, {
          ...columns[newIndex],
          key,
          model: key
        })
        if (this.noModel.includes(columns[newIndex].type)) {
          // 刪除不需要的model屬性
          delete columns[newIndex].model
        }
        if (typeof columns[newIndex].options !== 'undefined') {
          // 深拷貝options
          const optionsStr = JSON.stringify(columns[newIndex].options)
          columns[newIndex].options = JSON.parse(optionsStr)
        }
        if (typeof columns[newIndex].rules !== 'undefined') {
          // 深拷貝rules
          const rulesStr = JSON.stringify(columns[newIndex].rules)
          columns[newIndex].rules = JSON.parse(rulesStr)
        }
        if (typeof columns[newIndex].list !== 'undefined') {
          // 深拷貝list
          columns[newIndex].list = []
        }
        if (typeof columns[newIndex].columns !== 'undefined') {
          // 深拷貝columns
          const columnsStr = JSON.stringify(columns[newIndex].columns)
          columns[newIndex].columns = JSON.parse(columnsStr)
          // 復制時，清空數據
          columns[newIndex].columns.forEach(item => {
            item.list = []
          })
        }
        if (columns[newIndex].type === 'table') {
          // 深拷貝trs
          const trsStr = JSON.stringify(columns[newIndex].trs)
          columns[newIndex].trs = JSON.parse(trsStr)
          // 復制時，清空數據
          columns[newIndex].trs.forEach(item => {
            item.tds.forEach(val => {
              val.list = []
            })
          })
        }
      }
      // 深拷貝數據
      const listString = JSON.stringify(columns[newIndex])
      columns[newIndex] = JSON.parse(listString)
      this.$emit('handleSetSelectItem', columns[newIndex])
    },
    dragStart(evt, list) {
      // 拖拽結束,自動選擇拖拽的控件項
      this.$emit('handleSetSelectItem', list[evt.oldIndex])
    },
    handleSelectItem(record) {
      // 修改選擇Item
      this.$emit('handleSetSelectItem', record)
    },
    handleCopy(isCopy = true, data) {
      const traverse = array => {
        array.forEach((element, index) => {
          if (element.key === this.selectItem.key) {
            if (isCopy) {
              // 復制添加到選擇節點後面
              array.splice(index + 1, 0, element)
            } else {
              // 雙擊添加到選擇節點後面
              array.splice(index + 1, 0, data)
            }
            // 復制完成，重置key值
            const evt = {
              newIndex: index + 1
            }
            this.handleColAdd(evt, array, true)
            return
          }
          if (['grid', 'tabs', 'selectInputList'].includes(element.type)) {
            // 柵格布局
            element.columns.forEach(item => {
              traverse(item.list)
            })
          } else if (element.type === 'card') {
            // 卡片布局
            traverse(element.list)
          } else if (element.type === 'batch') {
            // 動態表格內復制
            if (!isCopy && !this.insertAllowedType.includes(data.type)) {
              // 插入不允許的字段時，直接return false
              return false
            }
            traverse(element.list)
          }
          if (element.type === 'table') {
            // 表格布局
            element.trs.forEach(item => {
              item.tds.forEach(val => {
                traverse(val.list)
              })
            })
          }
        })
      }
      traverse(this.data.list)
    },
    handleDelete() {
      // 刪除已選擇
      const traverse = array => {
        array = array.filter((element, index) => {
          if (['grid', 'tabs', 'selectInputList'].includes(element.type)) {
            // 柵格布局
            element.columns.forEach(item => {
              item.list = traverse(item.list)
            })
          }
          if (element.type === 'card' || element.type === 'batch') {
            // 卡片布局
            element.list = traverse(element.list)
          }
          if (element.type === 'table') {
            // 表格布局
            element.trs.forEach(item => {
              item.tds.forEach(val => {
                val.list = traverse(val.list)
              })
            })
          }
          if (element.key !== this.selectItem.key) {
            return true
          } else {
            if (array.length === 1) {
              this.handleSelectItem({ key: '' })
            } else if (array.length - 1 > index) {
              this.handleSelectItem(array[index + 1])
            } else {
              this.handleSelectItem(array[index - 1])
            }
            return false
          }
        })
        return array
      }

      this.data.list = traverse(this.data.list)
    },
    handleDownMerge() {
      // 向下合並

      // 判斷當前行是否是最後一行，最後一行無法向下合並
      if (
        this.rightMenuSelectValue.trs.length -
          this.rightMenuSelectValue.trs[this.trIndex].tds[this.tdIndex]
            .rowspan <=
        this.trIndex
      ) {
        this.$message.error('當前是最後一行，無法向下合並')
        return false
      }

      // 獲取當前單元格的rowspan
      // eslint-disable-next-line standard/computed-property-even-spacing
      const currentRowspan = this.rightMenuSelectValue.trs[this.trIndex].tds[
        this.tdIndex
      ].rowspan

      // 判斷下一列單元格與當前單元格的colspan是否一致，如果不一致則無法合並
      if (
        this.rightMenuSelectValue.trs[this.trIndex].tds[this.tdIndex]
          .colspan !==
        // eslint-disable-next-line standard/computed-property-even-spacing
        this.rightMenuSelectValue.trs[this.trIndex + currentRowspan].tds[
          this.tdIndex
        ].colspan
      ) {
        this.$message.error('當前表格無法向下合並')
        return false
      }

      // 獲取下一列單元格的rowspan
      // eslint-disable-next-line standard/computed-property-even-spacing
      const nextRowSpan = this.rightMenuSelectValue.trs[
        this.trIndex + currentRowspan
      ].tds[this.tdIndex].rowspan

      // 當前單元格rowspan等於當前單元格rowspan加上下一列單元格rowspan
      this.rightMenuSelectValue.trs[this.trIndex].tds[this.tdIndex].rowspan =
        currentRowspan + nextRowSpan

      // 將被合並的單元rowspan修改為0
      // eslint-disable-next-line standard/computed-property-even-spacing
      this.rightMenuSelectValue.trs[this.trIndex + currentRowspan].tds[
        this.tdIndex
      ].rowspan = 0

      // 清空被合並單元格list
      // eslint-disable-next-line standard/computed-property-even-spacing
      this.rightMenuSelectValue.trs[this.trIndex + currentRowspan].tds[
        this.tdIndex
      ].list = []
    },
    handleRightMerge() {
      // 向右合並
      // 獲取當前列的所有colspan總和
      const sumCols = this.rightMenuSelectValue.trs[this.trIndex].tds
        .map(item => item.colspan)
        .reduce(function(partial, value) {
          return partial + value
        })

      // 判斷是否是最後一列，最後一列無法繼續向右合並
      if (
        sumCols -
          this.rightMenuSelectValue.trs[this.trIndex].tds[this.tdIndex]
            .colspan <=
        this.tdIndex
      ) {
        this.$message.error('當前是最後一列，無法向右合並')
        return false
      }

      // 獲取當前單元格的colspan
      // eslint-disable-next-line standard/computed-property-even-spacing
      const currentColspan = this.rightMenuSelectValue.trs[this.trIndex].tds[
        this.tdIndex
      ].colspan

      // 判斷需要合並的單元格rowspan是否與當前單元格一致
      if (
        this.rightMenuSelectValue.trs[this.trIndex].tds[this.tdIndex]
          .rowspan !==
        // eslint-disable-next-line standard/computed-property-even-spacing
        this.rightMenuSelectValue.trs[this.trIndex].tds[
          this.tdIndex + currentColspan
        ].rowspan
      ) {
        this.$message.error('當前表格無法向右合並')
        return false
      }

      // 合並單元格colspan
      // eslint-disable-next-line standard/computed-property-even-spacing
      this.rightMenuSelectValue.trs[this.trIndex].tds[
        this.tdIndex
      // eslint-disable-next-line standard/computed-property-even-spacing
      ].colspan += this.rightMenuSelectValue.trs[this.trIndex].tds[
        this.tdIndex + currentColspan
      ].colspan

      // 將被合並的單元格colspan設置為0
      // eslint-disable-next-line standard/computed-property-even-spacing
      this.rightMenuSelectValue.trs[this.trIndex].tds[
        this.tdIndex + currentColspan
      ].colspan = 0

      // 情況被合並單元格的list
      // eslint-disable-next-line standard/computed-property-even-spacing
      this.rightMenuSelectValue.trs[this.trIndex].tds[
        this.tdIndex + currentColspan
      ].list = []
    },
    // 拆分單元格
    handleRightSplit() {
      // 獲取當前單元格的colspan及rowspan
      // eslint-disable-next-line standard/computed-property-even-spacing
      const { colspan, rowspan } = this.rightMenuSelectValue.trs[
        this.trIndex
      ].tds[this.tdIndex]

      for (
        let rowIndex = this.trIndex, rowLen = this.trIndex + rowspan;
        rowIndex < rowLen;
        rowIndex++
      ) {
        for (
          let colIndex = this.tdIndex, colLen = this.tdIndex + colspan;
          colIndex < colLen;
          colIndex++
        ) {
          if (rowIndex === this.trIndex && colIndex === this.tdIndex) continue
          this.rightMenuSelectValue.trs[rowIndex].tds.splice(colIndex, 1, {
            colspan: 1,
            rowspan: 1,
            list: []
          })
        }
      }
      // 修改當前單元格colspan、rowspan為1
      this.rightMenuSelectValue.trs[this.trIndex].tds[this.tdIndex].colspan = 1
      this.rightMenuSelectValue.trs[this.trIndex].tds[this.tdIndex].rowspan = 1
    },
    handleAddCol() {
      // 增加列

      this.rightMenuSelectValue.trs.forEach(item => {
        item.tds.splice(this.tdIndex + 1, 0, {
          colspan: 1,
          rowspan: 1,
          list: []
        })
      })
    },
    handleAddRow() {
      // 增加行
      // 獲取總col值
      const sumCols = this.rightMenuSelectValue.trs[0].tds
        .map(item => item.colspan)
        .reduce(function(partial, value) {
          return partial + value
        })
      const rowJson = { tds: [] }
      for (let i = 0; i < sumCols; i++) {
        rowJson.tds.push({
          colspan: 1,
          rowspan: 1,
          list: []
        })
      }

      // 取當前rowspan最大值
      let maxRowSpan = 1
      this.rightMenuSelectValue.trs[this.trIndex].tds.forEach(item => {
        if (maxRowSpan < item.rowspan) {
          maxRowSpan = item.rowspan
        }
      })

      // 在rowspan最大值處插入數據
      this.rightMenuSelectValue.trs.splice(
        this.trIndex + maxRowSpan,
        0,
        rowJson
      )
    },
    handleShowRightMenu(e, val, trIndex, tdIndex) {
      // 顯示右鍵菜單
      e.stopPropagation()
      // this.fileItem = item
      // 顯示
      this.showRightMenu = true

      // 定位
      this.menuTop = e.clientY
      this.menuLeft = e.clientX
      // this.rightMenuType = type
      // this.rightId = id
      this.activeArr = [val]
      this.rightMenuSelectValue = val
      this.trIndex = trIndex
      this.tdIndex = tdIndex
      return false
    },
    handleRemoveRightMenu() {
      // 取消右鍵菜單
      this.showRightMenu = false
    }
  },
  mounted() {
    // 添加監聽取消右鍵菜單
    document.addEventListener('click', this.handleRemoveRightMenu, true)
    document.addEventListener('contextmenu', this.handleRemoveRightMenu, true)
  },
  destroyed() {
    // 移除監聽
    document.removeEventListener('click', this.handleRemoveRightMenu, true)
    document.removeEventListener(
      'contextmenu',
      this.handleRemoveRightMenu,
      true
    )
  }
}
