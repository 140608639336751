//
//
//
//
//
//
//

export default {
  name: 'Loading',
  data() {
    return {
      title: 'loading...',
      isShowLoading: false,
    }
  },
  methods: {
    show(title) {
      this.isShowLoading = true
      if (title) {
        this.title = title
      }
    },
    hide() {
      this.isShowLoading = false
    },
  },
}
