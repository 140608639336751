//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import KFormModelItem from './module/KFormModelItem'
export default {
  name: 'KBatch',
  props: ['record', 'value', 'dynamicData', 'config', 'parentDisabled'],

  components: {
    KFormModelItem
  },
  watch: {
    value: {
      // value 需要深度監聽及默認先執行handler函數
      handler(val) {
        const initValue = val || []
        if (!initValue.length) {
          this.record.columns.forEach(item => {
            const itemData = {}
            item.list.forEach(e => e.model && (itemData[e.model] = null))
            itemData.checked = false
            itemData.value = item.value
            itemData.label = item.label
            initValue.push(itemData)
          })
        }

        this.dynamicValidateForm.domains = initValue
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      dynamicValidateForm: {
        domains: []
      }
    }
  },
  computed: {
    disabled() {
      return this.record.options.disabled || this.parentDisabled
    }
  },
  methods: {
    validationSubform() {
      let verification
      this.$refs.dynamicValidateForm.validate(valid => {
        verification = valid
      })
      return verification
    },
    resetForm() {
      this.$refs.dynamicValidateForm.resetFields()
    },
    onCheckboxChange(e, index) {
      this.dynamicValidateForm.domains[index].checked = e.target.checked
      this.handleInput()
    },
    onRadioChange(e, index) {
      this.dynamicValidateForm.domains.forEach(item => (item.checked = false))
      this.dynamicValidateForm.domains[index].checked = e.target.checked
      this.handleInput()
    },
    handleInput() {
      this.$emit('change', this.dynamicValidateForm.domains)
    }
  },
  mounted() {
    this.handleInput()
  }
}
