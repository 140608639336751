//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
      return {
          visible: false,
          openFlag: 'Y'
      }
  },
  methods: {
      open({ openFlag }) {
          this.openFlag = openFlag
          this.visible = false//true
      }
  }
}
