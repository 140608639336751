//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-enable */
import previewCode from '../../PreviewCode/index'
const codeVueFront = `<template>
  <div>
    <k-form-build
      :value="jsonData"
      ref="KFB"
      @submit="handleSubmit"
    />
    <button @click="getData">提交</button>
  </div>
</template>
<script>
export default {
  name: 'Demo',
  data () {
    return {
      jsonData: `
/* eslint-disable */
let codeVueLast = `
    }
  },
  methods: {
    handleSubmit(p) {
       // 通過表單提交按鈕觸發，獲取promise對象
       p().then(res => {
         // 獲取數據成功
         alert(JSON.stringify(res))
       })
         .catch(err => {
           console.log(err, '校驗失敗')
         })
     },
     getData() {
       // 通過函數獲取數據
       this.$refs.KFB.getData().then(res => {
         // 獲取數據成功
         alert(JSON.stringify(res))
       })
         .catch(err => {
           console.log(err, '校驗失敗')
         })
     }
  }
}
<\/script>`;

let codeHtmlFront = `<!DOCTYPE html>
<html>

<head>
  <title>表單設計器kcz</title>
  <meta charset="UTF-8">
  <link rel="stylesheet" href="http://unpkg.com/k-form-design/lib/k-form-design.css">
</head>

<body>
  <div class="app">
    <k-form-build ref="KFB" @submit="handleSubmit" :value="jsonData"></k-form-build>
    <button @click="getData">提交</button>
  </div>
  <script src="http://cdn.kcz66.com/vue.min.js"><\/script>
  <script src="http://unpkg.com/k-form-design/lib/k-form-design.umd.min.js"><\/script>
  <script>
    let jsonData = `;
    
    let codeHtmlLast = `
    let vm = new Vue({
      el: '.app',
      data: {
        jsonData
      },
      methods: {
        handleSubmit(p) {
          // 通過表單提交按鈕觸發，獲取promise對象
          p().then(res => {
            // 獲取數據成功
            alert(JSON.stringify(res))
          })
            .catch(err => {
              console.log(err, '校驗失敗')
            })
        },
        getData() {
          // 通過函數獲取數據
          this.$refs.KFB.getData().then(res => {
            // 獲取數據成功
            alert(JSON.stringify(res))
          })
            .catch(err => {
              console.log(err, '校驗失敗')
            })
        }
      }
    })
  <\/script>
</body>

</html>`
export default {
  name: 'CodeModal',
  data() {
    return {
      visible: false,
      editorVueJson: '',
      editorHtmlJson: '',
      jsonData: {}
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.editorVueJson =
          codeVueFront + JSON.stringify(this.jsonData) + codeVueLast

        this.editorHtmlJson =
          codeHtmlFront + JSON.stringify(this.jsonData) + codeHtmlLast
      }
    }
  },
  components: {
    previewCode
  },
  methods: {
    handleCancel() {
      this.visible = false
    }
  }
}
