//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
 * author kcz
 * date 2019-11-20
 * description 將json數據構建成表單
 */
import buildBlocks from './buildBlocks'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
// import moment from "moment";
export default {
  name: 'KFormBuild',
  data() {
    return {
      locale: zhCN,
      form: this.$form.createForm(this),
      validatorError: {},
      defaultDynamicData: {}
    }
  },
  // props: ["value", "dynamicData"],
  props: {
    value: {
      type: Object,
      required: true
    },
    dynamicData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    config: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outputString: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    buildBlocks
  },
  computed: {
    getDynamicData() {
      return typeof this.dynamicData === 'object' &&
        Object.keys(this.dynamicData).length
        ? this.dynamicData
        : window.$kfb_dynamicData || {}
    }
  },
  methods: {
    // moment,
    handleSubmit(e) {
      // 提交按鈕觸發，並觸發submit函數，返回getData函數
      e.preventDefault()
      this.$emit('submit', this.getData)
    },
    reset() {
      // 重置表單
      this.form.resetFields()
    },
    getData() {
      // 提交函數，提供父級組件調用
      return new Promise((resolve, reject) => {
        try {
          this.form.validateFields((err, values) => {
            if (err) {
              reject(err)
              /**
               * @author: lizhichao<meteoroc@outlook.com>
               * @Description: 多容器校驗時，提供error返回給多容器進行判斷。
               */
              this.validatorError = err
              return
            }
            this.validatorError = {}
            this.$refs.buildBlocks.forEach(item => {
              if (!item.validationSubform()) {
                reject(err)
              }
            })
            if (this.outputString) {
              // 需要所有value轉成字符串
              for (const key in values) {
                const type = typeof values[key]
                if (type === 'string' || type === 'undefined') {
                  continue
                } else if (type === 'object') {
                  values[key] = `k-form-design#${type}#${JSON.stringify(
                    values[key]
                  )}`
                } else {
                  values[key] = `k-form-design#${type}#${String(values[key])}`
                }
              }
              resolve(values)
            } else {
              resolve(values)
            }
          })
        } catch (err) {
          console.error(err)
          reject(err)
        }
      })
    },
    setData(json) {
      return new Promise((resolve, reject) => {
        try {
          if (this.outputString) {
            // 將非string數據還原
            for (const key in json) {
              if (!json[key].startsWith('k-form-design#')) {
                continue
              }
              const array = json[key].split('#')
              if (array[1] === 'object') {
                json[key] = JSON.parse(array[2])
              } else if (array[1] === 'number') {
                json[key] = Number(array[2])
              } else if (array[1] === 'boolean') {
                json[key] = Boolean(array[2])
              }
            }
            this.form.setFieldsValue(json)
          } else {
            this.form.setFieldsValue(json)
          }
          resolve(true)
        } catch (err) {
          console.error(err)
          reject(err)
        }
      })
    },

    // 批量設置某個option的值
    setOptions(fields, optionName, value) {
      fields = new Set(fields)

      // 遞歸遍歷控件樹
      const traverse = array => {
        array.forEach(element => {
          if (fields.has(element.model)) {
            this.$set(element.options, optionName, value)
          }
          if (element.type === 'grid' || element.type === 'tabs') {
            // 柵格布局 and 標簽頁
            element.columns.forEach(item => {
              traverse(item.list)
            })
          } else if (element.type === 'card' || element.type === 'batch') {
            // 卡片布局 and  動態表格
            traverse(element.list)
          } else if (element.type === 'table') {
            // 表格布局
            element.trs.forEach(item => {
              item.tds.forEach(val => {
                traverse(val.list)
              })
            })
          }
        })
      }
      traverse(this.value.list)
    },
    // 隱藏表單字段
    hide(fields) {
      this.setOptions(fields, 'hidden', true)
    },
    // 顯示表單字段
    show(fields) {
      this.setOptions(fields, 'hidden', false)
    },
    // 禁用表單字段
    disable(fields) {
      this.setOptions(fields, 'disabled', true)
    },
    // 啟用表單字段
    enable(fields) {
      this.setOptions(fields, 'disabled', false)
    },
    handleChange(value, key) {
      // 觸發change事件
      this.$emit('change', value, key)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setData(this.defaultValue)
    })
  }
}
