import { axios } from '@/utils/request'

/**
 * 獲取菜單列表
 *
 * @author yubaoshan
 * @param parameter
 * @returns {*}
 */
export function getMenuList (parameter) {
  return axios({
    url: '/sysMenu/list',
    method: 'get',
    params: parameter
  })
}

/**
 * 獲取系統菜單樹，用於新增，編輯時選擇上級節點
 *
 * @author yubaoshan
 * @date 2020/4/23 12:22
 */
export function getMenuTree (parameter) {
  return axios({
    url: '/sysMenu/tree',
    method: 'get',
    params: parameter
  })
}

/**
 * 增加菜單
 *
 * @author yubaoshan
 * @date 2020/4/24 23:23
 */
export function sysMenuAdd (parameter) {
  return axios({
    url: '/sysMenu/add',
    method: 'post',
    data: parameter
  })
}

/**
 * 增加菜單
 *
 * @author yubaoshan
 * @date 2020/4/24 23:23
 */
export function sysMenuDelete (parameter) {
  return axios({
    url: '/sysMenu/delete',
    method: 'post',
    data: parameter
  })
}

/**
 * 查看菜單詳情
 *
 * @author yubaoshan
 * @date 2020/4/25 01:11
 */
export function sysMenuDetail (parameter) {
  return axios({
    url: '/sysMenu/detail',
    method: 'post',
    data: parameter
  })
}

/**
 * 編輯系統菜單
 *
 * @author yubaoshan
 * @date 2020/4/25 01:11
 */
export function sysMenuEdit (parameter) {
  return axios({
    url: '/sysMenu/edit',
    method: 'post',
    data: parameter
  })
}

/**
 * 獲取系統菜單樹，用於給角色授權時選擇
 *
 * @author yubaoshan
 * @date 2020/6/2 17:30
 */
export function SysMenuTreeForGrant (parameter) {
  return axios({
    url: '/sysMenu/treeForGrant',
    method: 'get',
    params: parameter
  })
}

/**
 * 根據系統切換菜單
 *
 * @author yubaoshan
 * @date 2020/6/28 15:25
 */
export function sysMenuChange (parameter) {
  return axios({
    url: '/sysMenu/change',
    method: 'post',
    data: parameter
  })
}
