//
//
//
//
//
//
//
//
//
//

export default {
  name: 'XDown',
  data () {
    return {
      batchExportLoading: false
    }
  },
  methods: {
    /**
     * 本控件中點擊按鈕事件
     */
    batchExport () {
      this.batchExportLoading = true
      // 將其傳達到上個界面
      this.$emit('batchExport', '')
    },

    /**
     * 通過返回的元素通過瀏覽器下載
     * 也就是接受使用這個組件的地方吧下載的內容傳過來，
     * 但是這個組件本公子編寫的時候只想的適用於導出excel來使用，下載文件呀圖片方面的重新整個組件即可
     */
    downloadfile (res) {
      this.batchExportLoading = false
      var blob = new Blob([res.data], { type: 'application/octet-stream;charset=UTF-8' })
      var contentDisposition = res.headers['content-disposition']
      var patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
      var result = patt.exec(contentDisposition)
      var filename = result[1]
      var downloadElement = document.createElement('a')
      var href = window.URL.createObjectURL(blob) // 創建下載的鏈接
      var reg = /^["](.*)["]$/g
      downloadElement.style.display = 'none'
      downloadElement.href = href
      downloadElement.download = decodeURI(filename.replace(reg, '$1')) // 下載後文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() // 點擊下載
      document.body.removeChild(downloadElement) // 下載完成移除元素
      window.URL.revokeObjectURL(href)
    }
  }
}
