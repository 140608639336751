//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Trend',
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-trend'
    },
    /**
       * 上升下降標識：up|down
       */
    flag: {
      type: String,
      required: true
    },
    /**
       * 顏色反轉
       */
    reverseColor: {
      type: Boolean,
      default: false
    }
  }
}
