//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
 * author kcz
 * date 2019-11-20
 */
// import moment from "moment";

import UploadFile from '../../UploadFile'
import UploadImg from '../../UploadImg'
import KDatePicker from '../../KDatePicker'
import KTimePicker from '../../KTimePicker'
import ComponentArray from '../../core/components_use'
const _ = require('lodash/object')

export default {
  name: 'KFormItem',
  props: [
    'record',
    'domains',
    'index',
    'value',
    'parentDisabled',
    'dynamicData',
    'config'
  ],
  components: {
    UploadImg,
    UploadFile,
    KDatePicker,
    KTimePicker
  },
  computed: {
    componentItem() {
      return ComponentArray[this.record.type]
    },
    componentOption() {
      return _.omit(this.record.options, ['defaultValue', 'disabled'])
    }
  },
  methods: {
    handleChange(e) {
      let value = e
      if (e.target) {
        value = e.target.value
      }
      this.$emit('input', value)
    }
  }
}
