//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import config from '@/config/defaultSettings'
import { mixin, mixinDevice } from '@/utils/mixin'
import { triggerWindowResizeEvent } from '@/utils/util'
import { mapActions, mapState } from 'vuex'

import GlobalFooter from '@/components/GlobalFooter'
import GlobalHeader from '@/components/GlobalHeader'
import SideMenu from '@/components/Menu/SideMenu'
import SettingDrawer from '@/components/SettingDrawer'
import { convertRoutes } from '@/utils/routeConvert'
import RouteView from './RouteView'

export default {
  name: 'BasicLayout',
  mixins: [mixin, mixinDevice],
  components: {
    RouteView,
    SideMenu,
    GlobalHeader,
    GlobalFooter,
    SettingDrawer,
  },
  data() {
    return {
      production: config.production,
      collapsed: false,
      menus: [],
    }
  },
  computed: {
    ...mapState({
      // 動態主路由
      mainMenu: (state) => state.permission.addRouters,
      admintype: (state) => state.user.admintype,
    }),
    contentPaddingLeft() {
      if (!this.fixSidebar || this.isMobile()) {
        return '0'
      }
      if (this.sidebarOpened) {
        return '230px'
      }
      return '80px'
    },
  },
  watch: {
    sidebarOpened(val) {
      this.collapsed = !val
    },
    // 菜單變化
    mainMenu(val) {
      this.setMenus()
    },
  },
  created() {
    if (this.isMobile()) {
      const { name, query } = this.$route
      switch (name) {
        case 'fileStream_Approval_Manage':
          this.$router.replace({ name: 'mobileApproval', query })
          break
        case 'fileStream_Approval_process':
        case 'fileStream_documentSend':
          this.$router.replace({ name: 'showMobileApproval', query })
          break
        default:
          this.$router.replace({ name: 'mobileHome', query })
      }
    }
    this.setMenus()
    console.log(this.$route, 'created')
    /* const routes = convertRoutes(this.mainMenu.find(item => item.path === '/'))
    this.menus = (routes && routes.children) || [] */
    this.collapsed = !this.sidebarOpened
  },
  mounted() {
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed
        setTimeout(() => {
          this.collapsed = !this.collapsed
        }, 16)
      })
    }
  },
  methods: {
    ...mapActions(['setSidebar']),
    // 重新生成
    setMenus() {
      const routes = convertRoutes(this.mainMenu.find((item) => item.path === '/'))
      this.menus = (routes && routes.children) || []
    },
    toggle() {
      this.collapsed = !this.collapsed
      this.setSidebar(!this.collapsed)
      triggerWindowResizeEvent()
    },
    paddingCalc() {
      let left = ''
      if (this.sidebarOpened) {
        left = this.isDesktop() ? '230px' : '80px'
      } else {
        left = (this.isMobile() && '0') || (this.fixSidebar && '80px') || '0'
      }
      return left
    },
    menuSelect() {},
    drawerClose() {
      this.collapsed = false
    },
  },
}
