//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FullModelView',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    placement: {
      type: String,
      default: 'right'
    },
    destroyOnClose: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleCancel() {
      this.$emit('cancel')
    }
  }
}
